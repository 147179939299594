<template>
    <default-layout>
        <div class="px-6 pt-6 min-h-screen">
            <header class="flex mb-5 items-center justify-between">
                <h1>My Rack</h1>
                <ta-button
                    theme="outline"
                    @click="() => addDevice()"
                >
                    Add device
                    <v-icon name="plus" />
                </ta-button>
            </header>

            <div class="flex flex-col md:flex-row">
                <div class="flex-grow md:pr-4">
                    <rack-device
                        v-for="device in devices"
                        :key="device.sn"
                        :device="device"
                    />

                    <rack-empty-state v-if="devices.length == 0 && !refreshing" />
                </div>

                <div class="flex-grow md:pl-4">
                    <h2>Recent activity</h2>

                    <ul v-if="cookedDeviceEvents.length > 0">
                        <li
                            v-for="event in cookedDeviceEvents"
                            :key="event.id"
                        >
                            {{ event.when | wordsago }} ago
                            | {{ event.text }}
                            | <router-link :to="event.location">
                                {{ event.device.alias }}
                            </router-link>
                        </li>
                    </ul>

                    <div
                        v-else
                        class="mt-12 text-gray-500 border-l-2 rounded p-3 max-w-sm m-auto flex items-center"
                    >
                        <v-icon
                            scale="3"
                            class="flex-shrink-0 mr-3"
                            name="history"
                        />
                        <p>
                            There's no recent activity!
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <main-footer class="mt-10" />
    </default-layout>
</template>

<script>
import sortBy from "lodash/sortBy"
import axios from "axios"

import { cookDeviceEvent } from "~/lib/utils"

import TaButton from "~/components/TaButton"
import AddDeviceModal from "~/components/AddDeviceModal"
import DefaultLayout from "~/layouts/DefaultLayout"
import RackDevice from "~/components/RackDevice"
import RackEmptyState from "~/components/RackEmptyState"
import MainFooter from "~/components/MainFooter"

export default {
    inject: ["pushModal"],

    props: {
        sn: {
            type: String,
            default: "",
        }
    },

    data() {
        return {
            refreshing: false
        }
    },

    computed: {
        devices() {
            return this.$store.getters.userDevices
        },

        cookedDeviceEvents() {
            const events = []
            for (let device of this.devices) {
                const detail = this.$store.state.details[device.id]
                if (!detail) continue

                for (let event of detail.events) {
                    events.push(cookDeviceEvent(event, {
                        device,
                        location: `/device/${device.id}`
                    }))
                }
            }
            return sortBy(events, e => e.when)
        }
    },

    serverPrefetch() {
        return this.refreshDevices()
    },

    async mounted() {
        if (this.devices.length == 0) {
            this.refreshDevices()
        }

        this.$store.dispatch("refreshDeviceEvents")

        if (this.sn) {
            const response = await axios.get(`/public-device/${this.sn}`)
            const device = response.data.device
            const type = device.analog ? "analog" : "digital"
            this.addDevice(this.sn, type)
        }
    },

    methods: {
        async refreshDevices() {
            this.refreshing = true
            await this.$store.dispatch("refreshDevices")
            this.refreshing = false
        },

        addDevice(sn, type) {
            const pop = this.pushModal(AddDeviceModal, {
                props: {
                    sn, type,
                },
                on: {
                    add: async ({ sn, sc1, sc2, sc3, pin }) => {
                        const go = await this.$store.dispatch("addDevice", {
                            sn, pin, sc1, sc2, sc3
                        })
                        if (go) {
                            pop()
                        }
                    },
                    hide: () => {
                        pop()
                    }
                }
            })
        },
    },

    components: {
        TaButton,
        RackDevice,
        DefaultLayout,
        RackEmptyState,
        MainFooter,
    }
}
</script>
