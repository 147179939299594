<template>
    <div class="min-h-screen mx-auto max-w-sm px-3 pt-4 pb-16 flex flex-col justify-center">
        <img
            class="mb-10"
            :src="logoUrl"
        >

        <h1 class="text-center">
            REGISTER
        </h1>

        <form
            class="mt-4"
            @submit.prevent="submit"
        >
            <div class="mtsp-4">
                <label class="label">
                    Full name
                </label>
                <input-text
                    v-model="fullName"
                    placeholder="Full name"
                />
                <input-hint
                    v-if="$v.fullName.$error"
                    :hints="fullNameHints"
                />
            </div>

            <div class="mtsp-4">
                <label class="label">
                    Email
                </label>
                <input-text
                    v-model="email"
                    type="email"
                    placeholder="Email"
                />
                <input-hint
                    v-if="$v.email.$error"
                    :hints="emailHints"
                />
            </div>

            <div class="mtsp-4">
                <label class="label">
                    Password
                </label>
                <input-text
                    v-model="password"
                    type="password"
                    placeholder="Password"
                />
                <input-hint
                    v-if="$v.password.$error"
                    :hints="passwordHints"
                />
            </div>

            <div class="mtsp-4">
                <label class="label">
                    Repeat password
                </label>
                <input-text
                    v-model="passwordConfirmation"
                    type="password"
                    placeholder="Repeat password"
                />
                <input-hint
                    v-if="$v.passwordConfirmation.$error"
                    :hints="passwordConfirmationHints"
                />
            </div>

            <div class="mtsp-4">
                <label class="label">
                    Device
                </label>
                <div
                    v-if="device"
                    class="flex items-center px-4 py-1 cursor-pointer bg-gray-200 hover:bg-gray-300"
                    @click="device = null"
                >
                    <div class="flex flex-col flex-1">
                        <span>
                            {{ device.model }}
                        </span>
                        <span class="text-xs">
                            {{ device.sn }}
                        </span>
                    </div>
                    <div>
                        <v-icon
                            class="text-gray-700"
                            name="regular/times-circle"
                        />
                    </div>
                </div>
                <p
                    v-else
                    class="px-px text-gray-700 py-2"
                >
                    Do you want to register with a
                    <button
                        type="button"
                        @click="() => addDevice()"
                    >
                        device
                    </button>?
                </p>
            </div>

            <div class="text-gray-700 mtsp-4">
                <input
                    v-model="policy"
                    type="checkbox"
                >
                I have read and accept the
                <button
                    type="button"
                    @click="showPrivacyPolicty"
                >
                    privacy policy
                </button>.
            </div>

            <div class="flex items-baseline mtsp-4">
                <router-link
                    to="/login"
                    class="item-start w-full text-center"
                >
                    Go back
                </router-link>
                <ta-button
                    :disabled="!policy"
                    class="w-full"
                >
                    Register
                </ta-button>
            </div>
        </form>

        <div class="fixed bottom-0 inset-x-0 justify-between flex flex-wrap bg-white">
            <social-bar />
            <love-bar />
        </div>
    </div>
</template>

<script>
import axios from "axios"
import {
    required,
    email,
    minLength,
    sameAs,
} from "vuelidate/lib/validators"

import InputText from "~/components/InputText"
import TaButton from "~/components/TaButton"
import InputHint from "~/components/InputHint"

import SocialBar from "~/components/SocialBar"
import LoveBar from "~/components/LoveBar"

import AddDeviceModal from "~/components/AddDeviceModal"
import PrivacyPolicyModal from "~/components/PrivacyPolicyModal"

import logoUrl from "~/images/favic.gif"

export default {
    inject: ["pushModal"],

    props: {
        with: {
            type: String,
            default: "",
        }
    },

    data() {
        return {
            email: "",
            password: "",
            passwordConfirmation: "",
            fullName: "",
            sn: "",
            pin: "",
            sc1: "",
            sc2: "",
            sc3: "",

            policy: false,

            logoUrl, // TODO

            device: null,
        }
    },

    computed: {
        emailHints() {
            return [
                !this.$v.email.required && "Email is required",
                !this.$v.email.email && "Email is invalid",
            ]
        },

        passwordHints() {
            return [
                !this.$v.password.required && "Password is required",
                !this.$v.password.minLength && `Password must have at least ${this.$v.password.$params.minLength.min} characters`
            ]
        },

        passwordConfirmationHints() {
            return [
                !this.$v.passwordConfirmation.sameAs && "Passwords don't match",
            ]
        },

        fullNameHints() {
            return [
                !this.$v.fullName.required && "Name is required",
            ]
        },
    },

    validations: {
        email: {
            required,
            email,
        },
        password: {
            required,
            minLength: minLength(10)
        },
        passwordConfirmation: {
            sameAsPassword: sameAs("password")
        },
        fullName: {
            required,
        }
    },

    async mounted() {
        if (this.with) {
            const sn = this.with
            const response = await axios.get(`/public-device/${sn}`)
            const device = response.data.device
            const type = device.analog ? "analog" : "digital"
            this.addDevice(sn, type)
        }
    },

    methods: {
        async submit() {
            this.$v.$touch()
            if (this.$v.$invalid) return // TODO flash

            const go = await this.$store.dispatch("register", {
                email: this.email,
                password: this.password,
                fullName: this.fullName,
                sn: this.sn,
                pin: this.pin,
                sc1: this.sc1,
                sc2: this.sc2,
                sc3: this.sc3,
            })

            if (go) {
                this.$router.push("/")
            }
        },

        addDevice(sn, type) {
            const pop = this.pushModal(AddDeviceModal, {
                props: {
                    sn, type,
                },
                on: {
                    add: async ({ sn, pin, sc1, sc2, sc3 }) => {
                        await axios.post("/add-device-dry-run", {
                            sn, pin, sc1, sc2, sc3
                        })
                        .then(() => true, error => this.$store.dispatch("notifyOfError", error), false)

                        const response = await axios.get(`/public-device/${sn}`)
                        this.device = response.data.device
                        this.sn = sn
                        this.pin = pin
                        this.sc1 = sc1
                        this.sc2 = sc2
                        this.sc3 = sc3
                        pop()
                    },
                    hide: () => {
                        pop()
                    }
                }
            })
        },

        showPrivacyPolicty() {
            const pop = this.pushModal(PrivacyPolicyModal, {
                on: {
                    hide: () => {
                        pop()
                    }
                }
            })
        }
    },

    components: {
        InputText,
        TaButton,
        InputHint,
        SocialBar,
        LoveBar,
    }
}
</script>
