<template>
    <div>
        <color-swatch
            ref="swatch"
            class="cursor-pointer"
            :color="value"
            @click.native="showPopUp = true"
        />
        <pop-up
            v-if="showPopUp"
            :reference="$refs.swatch.$el"
            class="bg-white p-2 rounded overflow-hidden shadow"
            @hide="showPopUp = false"
        >
            <div
                v-for="(chunk, idx) in chunkedColors"
                :key="idx"
                class="flex"
            >
                <color-swatch
                    v-for="color in chunk"
                    :key="color"
                    :color="color"
                    class="cursor-pointer"
                    @click.native="click(color)"
                />
            </div>
        </pop-up>
    </div>
</template>

<script>
import ColorSwatch from "./ColorSwatch"
import PopUp from "./PopUp"

import { sqrtChunk } from "~/lib/utils"

export default {
    props: {
        value: {
            type: String,
            required: true,
        }
    },

    data() {
        return {
            showPopUp: false,
            colors: [
                "red", "orange", "yellow", "green",
                "teal", "blue", "indigo", "purple",
                "pink", "black", "white", "gray",
            ] // TODO need 16
        }
    },

    computed: {
        chunkedColors() {
            return sqrtChunk(this.colors)
        }
    },

    methods: {
        click(color) {
            this.$emit("input", color)
            this.showPopUp = false
        }
    },

    components: {
        ColorSwatch,
        PopUp,
    }
}
</script>
