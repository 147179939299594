<template>
    <default-layout>
        <div
            v-if="device"
            class="p-6 min-h-screen"
        >
            <header class="flex items-center mb-4">
                <router-link
                    to="/rack"
                    class="mr-3"
                >
                    <v-icon
                        name="arrow-left"
                        scale="1.5"
                    />
                </router-link>

                <h1>
                    {{ device.alias }}
                </h1>

                <ta-button
                    ref="actions"
                    theme="negative"
                    class="ml-auto text-gray-600"
                    @click="togglePopUp"
                >
                    <v-icon name="ellipsis-v" />
                </ta-button>
            </header>

            <div
                v-if="device.lockedAt"
                class="my-6 border p-3 text-center"
            >
                <v-icon
                    class="mr-1"
                    name="lock"
                />
                <span>Device is locked</span>
            </div>

            <template v-if="mounted">
                <div
                    v-show="$mq.lg"
                    class="flex"
                >
                    <div class="pr-4 w-1/2">
                        <device-info
                            v-if="device && detail"
                            class="p-4 border rounded shadow"
                            v-bind="{ device, detail }"
                        />
                        <device-recent-activity
                            v-if="!device.analog && device.lastPulseDate"
                            class="p-4 border rounded shadow mt-6"
                            :events="events"
                            :last-pulse-date="new Date(device.lastPulseDate)"
                        />
                        <device-appears-in
                            v-if="detail && detail.tracks && detail.tracks.length > 0"
                            class="p-4 border rounded shadow mt-6"
                            :tracks="detail.tracks"
                        />
                    </div>
                    <div class="pl-4 w-1/2">
                        <device-sensors
                            v-if="!device.analog"
                            class="p-4 border rounded shadow"
                            :payloads="payloads"
                        />

                        <device-location
                            v-if="!device.analog && lastPayload && lastPayload.geolocation"
                            class="p-4 border rounded shadow mt-6"
                            :geolocation="lastPayload.geolocation"
                        />

                        <device-tree-info
                            v-if="detail && detail.treeInfo"
                            class="p-4 border rounded shadow"
                            :tree-info="detail.treeInfo"
                        />
                    </div>
                </div>

                <div v-show="!$mq.lg">
                    <device-info
                        v-if="device && detail"
                        v-bind="{ device, detail }"
                    />

                    <device-recent-activity
                        v-if="!device.analog && device.lastPulseDate"
                        class="mt-6"
                        :events="events"
                        :last-pulse-date="new Date(device.lastPulseDate)"
                    />

                    <device-sensors
                        v-if="!device.analog"
                        class="mt-6"
                        :payloads="payloads"
                    />

                    <device-location
                        v-if="!device.analog && lastPayload && lastPayload.geolocation"
                        class="mt-6"
                        :geolocation="lastPayload.geolocation"
                    />

                    <device-appears-in
                        v-if="detail && detail.tracks && detail.tracks.length > 0"
                        class="mt-6"
                        :tracks="detail.tracks"
                    />

                    <device-tree-info
                        v-if="detail && detail.treeInfo"
                        class="mt-6"
                        :tree-info="detail.treeInfo"
                    />
                </div>
            </template>

            <pop-up
                v-if="popUpVisible"
                class="bg-white rounded flex flex-col px-2 py-4 shadow-lg"
                :reference="$refs.actions.$el"
                @hide="popUpVisible=false"
            >
                <router-link
                    :to="`/genuine/${device.sn}`"
                    target="_blank"
                    rel="noreferrer noopener"
                    class="mtsp-2"
                >
                    <ta-button
                        class="text-left"
                        theme="negative"
                    >
                        <v-icon
                            class="mr-1"
                            name="stamp"
                        />
                        Digital certificate
                    </ta-button>
                </router-link>
                <ta-button
                    theme="negative"
                    class="text-left mtsp-2"
                    @click="renameDevice"
                >
                    <v-icon
                        class="mr-1"
                        name="pencil-alt"
                    />
                    Rename
                </ta-button>
                <template v-if="!device.analog">
                    <ta-button
                        v-if="!device.lockedAt"
                        theme="negative"
                        class="mtsp-2 text-left"
                        @click="lockDevice"
                    >
                        <v-icon
                            class="mr-1"
                            name="lock"
                        />
                        Lock device
                    </ta-button>
                    <ta-button
                        v-else
                        theme="negative"
                        class="mtsp-2 text-left"
                        @click="unlockDevice"
                    >
                        <v-icon
                            class="mr-1"
                            name="lock-open"
                        />
                        Unlock device
                    </ta-button>
                </template>
                <ta-button
                    theme="negative"
                    class="mtsp-2 text-left"
                    @click="fileIssue"
                >
                    <v-icon
                        class="mr-1"
                        name="hands-helping"
                    />
                    File an issue
                </ta-button>
                <ta-button
                    theme="negative"
                    class="mtsp-2 text-left"
                    @click="unlink"
                >
                    <v-icon
                        class="mr-1"
                        name="window-close"
                    />
                    Unlink
                </ta-button>
            </pop-up>
        </div>

        <main-footer class="mt-10" />
    </default-layout>
</template>

<script>
import DefaultLayout from "~/layouts/DefaultLayout"
import PopUp from "~/components/PopUp"
import TaButton from "~/components/TaButton"

import DeviceInfo from "~/components/DeviceInfo"
import DeviceRecentActivity from "~/components/DeviceRecentActivity"
import DeviceSensors from "~/components/DeviceSensors"
import DeviceLocation from "~/components/DeviceLocation"
import DeviceAppearsIn from "~/components/DeviceAppearsIn"
import MainFooter from "~/components/MainFooter"
import DeviceTreeInfo from "~/components/DeviceTreeInfo"

import RenameDeviceModal from "~/components/RenameDeviceModal"

export default {
    inject: ["pushModal", "pushDialog"],

    props: {
        id: {
            type: Number,
            required: true
        }
    },

    data() {
        return {
            popUpVisible: false,
            mounted: false,
            deviceAlias: "",
        }
    },

    computed: {
        events() {
            if (!this.detail) return []
            if (!this.detail.events) return []
            return this.detail.events
        },

        device() {
            return this.$store.state.devices[this.id]
        },

        detail() {
            return this.$store.state.details[this.id]
        },

        payloads() {
            if (!this.detail) return []
            if (!this.detail.payloads) return []
            return this.detail.payloads
        },

        lastPayload() {
            if (!this.detail) return null
            if (!this.detail.payloads) return null
            return this.detail.payloads[0]
        },
    },

    serverPrefetch() {
        return Promise.all([
            this.refreshDevices(),
            this.refreshDetail()])
    },

    mounted() {
        if (!this.device) this.refreshDevices()
        this.refreshDetail()
        this._interval = window.setInterval(() => {
            this.refreshDetail()
        }, 10000)
        this.mounted = true
    },

    beforeDestroy() {
        window.clearInterval(this._interval)
    },

    methods: {
        refreshDevices() {
            return this.$store.dispatch("refreshDevices")
        },

        refreshDetail() {
            return this.$store.dispatch("refreshDetail", this.id)
        },

        togglePopUp() {
            this.popUpVisible = !this.popUpVisible
        },

        async lockDevice() {
            this.popUpVisible = false
            const go = await this.pushDialog("Are you sure you want to lock this device?", {
                mode: "confirm",
                acceptText: "Lock",
            })
            if (!go) return
            this.$store.dispatch("lockDevice", this.id)
        },

        async unlockDevice() {
            this.popUpVisible = false
            const go = await this.pushDialog("Are you sure you want to unlock this device?", {
                mode:"confirm",
                acceptText: "Unlock",
            })
            if (go) {
                this.$store.dispatch("unlockDevice", this.id)
            }
        },

        renameDevice() {
            this.popUpVisible = false
            const pop = this.pushModal(RenameDeviceModal, {
                props: {
                    device: this.device
                },
                on: {
                    close: () => {
                        pop()
                    }
                }
            })
        },

        fileIssue() {
            this.$router.push(`/support/${this.device.sn}`)
        },

        async unlink() {
            this.popUpVisible = false
            const go = await this.pushDialog("Are you sure that you want to unlink this device? You will lose its event history and you won't be able to use it in new tracks.", {
                mode: "confirm",
                acceptText: "Unlink",
            })
            if (go) {
                await this.$store.dispatch("unlinkDevice", this.id)
                this.$router.push("/rack")
            }
        }
    },

    components: {
        PopUp,
        TaButton,
        DefaultLayout,
        DeviceInfo,
        DeviceRecentActivity,
        DeviceSensors,
        DeviceLocation,
        MainFooter,
        DeviceAppearsIn,
        DeviceTreeInfo,
    }
}
</script>
