<template>
    <div class="dialog-box relative max-w-4xl shadow rounded bg-white p-6">
        <ta-button
            v-if="step != 'select-type'"
            class="absolute top-0 left-0"
            theme="negative"
            @click="step = 'select-type'"
        >
            <v-icon name="arrow-left" />
        </ta-button>
        <ta-button
            class="absolute top-0 right-0"
            theme="negative"
            @click="$emit('hide')"
        >
            <v-icon name="times" />
        </ta-button>

        <h1 class="mb-6 text-center">
            <template v-if="step == 'select-type'">
                Add device
            </template>
            <template v-else-if="step == 'digital'">
                Add digital device
            </template>
            <template v-else-if="step == 'analog'">
                Add analog device
            </template>
            <template v-else-if="step == 'flavour'">
                Add Flavour
            </template>
        </h1>

        <transition
            name="fade"
            leave-active-class="absolute"
        >
            <div v-if="step == 'select-type'">
                <p class="mb-4 text-gray-700">
                    Which type of device do you want to add?
                </p>

                <div class="flex border-t-2 border-l-2 border-b-2 border-gray-900 rounded overflow-hidden">
                    <button
                        class="flex-1 p-8 uppercase font-medium text-gray-900 text-xl relative"
                        @click="step = 'analog'"
                    >
                        Analog
                        <svg
                            class="absolute fill-current text-white top-0 z-10 w-2"
                            style="left: 100%; height:50%;"
                            viewBox="0 0 1 1"
                            preserveAspectRatio="none"
                        >
                            <path d="M0 0 L1 0 L0 1 Z" />
                        </svg>
                    </button>
                    <button
                        class="flex-1 bg-gray-900 text-gray-200 p-8 uppercase font-medium text-xl relative"
                        disabled
                        @click="step = 'digital'"
                    >
                        <svg
                            class="absolute fill-current text-gray-900 bottom-0 z-10 w-2"
                            style="right: 100%; height:50%;"
                            viewBox="0 0 1 1"
                            preserveAspectRatio="none"
                        >
                            <path d="M1 0 L1 1 L0 1 Z" />
                        </svg>
                        Digital
                    </button>
                </div>
                <div>
                    <button
                        class="text-gray-700 text-xs"
                        @click="step = 'flavour'"
                    >
                        Add Flavour without security codes
                    </button>
                </div>
            </div>
            <digital-form
                v-else-if="step == 'digital'"
                :initial-sn="sn"
                @add="$listeners.add"
            />
            <analog-form
                v-else-if="step == 'analog'"
                :initial-sn="sn"
                @add="$listeners.add"
            />
            <flavour-form
                v-else-if="step == 'flavour'"
                @add="$listeners.add"
            />
        </transition>
    </div>
</template>

<script>
import TaButton from "~/components/TaButton"
import DigitalForm from "~/components/AddDeviceModalDigitalForm"
import AnalogForm  from "~/components/AddDeviceModalAnalogForm"
import FlavourForm from "~/components/AddDeviceModalFlavourForm"

export default {
    props: {
        sn: {
            type: String,
            default: "",
        },
        type: {
            type: String,
            default: "",
        },
    },

    data() {
        return {
            step: this.type || "select-type", // select-type | analog | digital
        }
    },

    components: {
        TaButton,
        DigitalForm,
        AnalogForm,
        FlavourForm,
    }
}
</script>

<style scoped>
.dialog-box {
    width: calc(100% - 2rem);
}
</style>
