<template>
    <button
        class="socket"
        :class="socketClass"
        tabindex="0"
        @click="input"
    >
        <span
            class="ball"
            :style="ballStyle"
        />
    </button>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: () => false,
        }
    },

    computed: {
        ballStyle() {
            const x = this.value ? "100%" : 0
            return {
                transform: `translateX(${x})`
            }
        },

        socketClass() {
            return {
                active: this.value
            }
        },
    },

    methods: {
        input() {
            this.$emit("input", !this.value)
        }
    }
}
</script>

<style scoped>
.socket {
    @apply outline-none border rounded-full relative bg-gray-300;
    height: 22px;
    width: 34px;

    transition: background-color 0.20s ease-in-out;
}

.socket.active {
    @apply bg-black;
}

.ball {
    @apply shadow rounded-full absolute bg-gray-100;

    top: 3px;
    left: 2px;
    height: 14px;
    width: 14px;

    transition: transform 0.20s ease-in-out, background-color 0.20s ease-in-out;
}

.active .ball {
    @apply bg-white;
}

.socket:hover .ball {
    @apply bg-white;
}
</style>
