<template>
    <div
        class="flex items-center border-l-4 py-2 shadow-md"
        :class="toastClass"
    >
        <div class="flex-shrink-0 px-4">
            <v-icon
                :class="iconClass"
                scale="1.5"
                :name="icon"
            />
        </div>
        <p class="px-2">
            <slot />
        </p>
    </div>
</template>

<script>
export default {
    props: {
        icon: {
            type: String,
            required: true
        },
        type: {
            type: String,
            default: "info"
        }
    },

    computed: {
        toastClass() {
            switch (this.type) {
                case "info": return "border-gray-600"
                case "error": return "border-red-400"
                case "warning": return "border-orange-300"
                default:
                    throw new Error(`Unknown type ${this.type}`)
            }
        },

        iconClass() {
            switch (this.type) {
                case "info": return "text-gray-800"
                case "error": return "text-red-700"
                case "warning": return "text-orange-600"
                default:
                    throw new Error(`Unknown type ${this.type}`)
            }
        }
    }
}
</script>
