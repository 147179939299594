import Vue from "vue"
import { sync } from "vuex-router-sync"
import Vuelidate from "vuelidate"

import "~/css/transitions.css"
import "~/css/mohave.css"

import { createRouter } from "./router"
import { createStore } from "./store"
import App from "~/App"
import "~/filters"
import "~/icons"
import Breakpoints from "~/lib/breakpoints"


Vue.config.productionTip = false

export function createApp(initialSize) {
    Vue.use(Vuelidate)

    Vue.use(Breakpoints, {
        breakpoints: {
            sm: 640,
            md: 768,
            lg: 1024,
            xl: 1280,
        },
        initialSize,
    })

    Vue.prototype.$swap = function(xs, i, j) {
        const tmp = xs[i]
        this.$set(xs, i, xs[j])
        this.$set(xs, j, tmp)
    }

    let lockers = 0
    Vue.directive("lock-scroll", {
        bind() {
            lockers += 1
            if (lockers > 0) document.body.style.overflow = "hidden"
        },

        unbind() {
            lockers -= 1
            if (lockers <= 0) document.body.style.overflow = ""
        }
    })

    const router = createRouter()
    const store = createStore()

    sync(store, router)

    const app = new Vue({
        render: h => h(App),
        router,
        store,
    })

    router.beforeEach((to, from, next) => {
        const isPublic = to.matched.every(m => m.meta.public)
        const isAdmin  = to.matched.some(m => m.meta.admin)
        if (!store.state.user && !isPublic) {
            next(`/login?go=${to.path}`)
        } else if (isAdmin && !store.state.user.isAdmin) {
            next(false)
        } else {
            next()
        }
    })

    return { app, router, store }
}
