import Vue from "vue"

import formatDistanceToNow from "date-fns/formatDistanceToNow"
import upperFirst from "lodash/upperFirst"

Vue.filter("ucfirst", upperFirst)

// "2011-03-14T00:00:00+00:00" => Date
Vue.filter("iso8601", v => new Date(v))

// Date => "1 minute ago"
Vue.filter("wordsago", v => formatDistanceToNow(v))

// Date => "DD-MM-YYYY HH:mm"
Vue.filter("datetime", v => v.toLocaleString())

// Date => "DD-MM-YYYY"
Vue.filter("date", v => v.toLocaleDateString({ dateStyle: "short" }))
