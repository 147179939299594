<template>
    <div class="p-2 border flex">
        <div class="flex flex-col">
            <div class="font-normal">
                {{ track.name }}
            </div>
            <div class="text-sm">
                Last activity: {{ lastRecordAt }}
            </div>
        </div>
        <div class="ml-auto flex flex-col justify-end text-xs">
            {{ track.recordCount }} {{ (track.recordCount > 1 || track.recordCount == 0) ? 'sessions' : 'session' }}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        track: {
            type: Object,
            required: true,
        }
    },

    computed: {
        lastRecordAt() {
            if (this.track.lastRecordAt) {
                return `${new Date(this.track.lastRecordAt).toLocaleString()}`
            } else {
                return "-"
            }
        }
    }
}
</script>
