<template>
    <transition name="side-panel">
        <div
            v-if="show"
            v-lock-scroll
            class="fixed-background"
            @click="click"
        >
            <div
                class="h-full max-w-xs bg-white shadow"
                :class="bodyClass"
                @click.stop
            >
                <slot />
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    props: {
        show: Boolean,
        bodyClass: {
            type: null,
            default: () => "",
        }
    },

    methods: {
        click() {
            this.$emit("hide")
        }
    }
}
</script>

<style>
.fixed-background {
    @apply fixed;

    background-color: rgba(50, 50, 50, 0.35);
    left: -100vw;
    padding: 100vh 100vw;
    right: -100vw;
    top: -100vh;
    bottom: -100vh;
}

.side-panel-enter-active,
.side-panel-leave-active {
    transition: transform 200ms ease-in-out,
                  opacity 200ms ease-in-out;
}

.side-panel-enter,
.side-panel-leave-to {
    opacity: 0;
    transform: translateX(-100vw);
}

.side-panel-enter-to,
.side-panel-leave {
}
</style>
