<template>
    <div class="min-h-screen mx-auto max-w-sm px-3 flex flex-col justify-center">
        <img
            class="mb-10"
            :src="logoUrl"
        >

        <h1 class="text-center">
            FORGOT YOUR PASSWORD?
        </h1>

        <form
            class="mt-4"
            @submit.prevent="submit"
        >
            <div class="mb-4">
                <input-text
                    v-model="email"
                    type="email"
                    placeholder="Email"
                />
                <input-hint
                    v-if="$v.email.$error"
                    :hints="emailHints"
                />
            </div>

            <div class="flex items-baseline">
                <router-link
                    to="/login"
                    class="item-start w-full text-center"
                >
                    Go back
                </router-link>
                <ta-button class="w-full">
                    Send link
                </ta-button>
            </div>
        </form>

        <div class="fixed bottom-0 inset-x-0 justify-between flex flex-wrap">
            <social-bar />
            <love-bar />
        </div>
    </div>
</template>

<script>
import {
    required,
    email,
} from "vuelidate/lib/validators"

import InputText from "~/components/InputText"
import TaButton from "~/components/TaButton"
import InputHint from "~/components/InputHint"

import SocialBar from "~/components/SocialBar"
import LoveBar from "~/components/LoveBar"

import logoUrl from "~/images/favic.gif"

export default {
    data() {
        return {
            email: "",

            logoUrl,
        }
    },

    computed: {
        emailHints() {
            return [
                !this.$v.email.required && "Email is required",
                !this.$v.email.email && "Email is invalid",
            ]
        },
    },

    validations: {
        email: {
            required,
            email,
        }
    },

    methods: {
        submit() {
            this.$v.$touch()
            if (this.$v.$invalid) return // TODO flash

            this.$store.dispatch("remember", {
                email: this.email
            })
            .then(() => {
                this.$router.push("/login")
            })
        }
    },

    components: {
        InputText,
        TaButton,
        InputHint,
        SocialBar,
        LoveBar,
    }
}
</script>
