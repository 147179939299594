<template>
    <ul class="pt-1 mt-px text-xs text-red-400">
        <li
            v-for="hint in compactedHints"
            :key="hint"
        >
            {{ hint }}
        </li>
    </ul>
</template>

<script>
import compact from "lodash/compact"

export default {
    props: {
        hints: {
            type: Array,
            default: () => [],
        }
    },

    computed: {
        compactedHints() {
            return compact(this.hints)
        }
    }
}
</script>
