<template>
    <div class="p-3">
        <div class="flex items-baseline">
            <button @click="$emit('select', record)">
                <template v-if="record.name">
                    {{ record.name }}
                </template>
                <template v-else>
                    {{ record.createdAt | iso8601 | wordsago }} ago
                </template>
            </button>
            <span class="text-sm text-gray-700 ml-2">
                {{ record.createdAt | iso8601 | datetime }}
            </span>
            <button
                ref="btn"
                class="ml-auto text-gray-600"
                @click="popUpIsVisible = !popUpIsVisible"
            >
                <v-icon name="ellipsis-h" />
            </button>
        </div>
        <div class="text-sm truncate">
            {{ names }}
        </div>

        <pop-up
            v-if="popUpIsVisible"
            class="bg-white rounded flex flex-col px-2 py-4 shadow-lg"
            :reference="$refs.btn"
            @hide="popUpIsVisible=false"
        >
            <ta-button
                theme="negative"
                class="text-left"
                @click="rename"
            >
                <v-icon
                    class="mr-1"
                    name="pencil-alt"
                />
                Rename
            </ta-button>
            <ta-button
                theme="negative"
                class="mt-2 text-left"
                @click="deleteEntry"
            >
                <v-icon
                    class="mr-1"
                    name="times"
                />
                Remove
            </ta-button>
        </pop-up>
    </div>
</template>

<script>
import PopUp from "~/components/PopUp"
import TaButton from "~/components/TaButton"
import RenameModal from "~/components/RenameModal"

export default {
    inject: ["pushModal", "pushDialog"],

    props: {
        record: {
            type: Object,
            required: true,
        }
    },

    data() {
        return {
            popUpIsVisible: false,
        }
    },

    computed: {
        names() {
            return this.record.entries
                .map(e => e.name)
                .join(", ")
        }
    },

    methods: {
        rename() {
            const pop = this.pushModal(RenameModal, {
                props: {
                    initialName: this.record.name
                },
                on: {
                    rename: (name) => {
                        this.$emit("rename", name)
                        pop()
                    },
                    close: () => {
                        pop()
                    }
                }
            })
        },

        async deleteEntry() {
            const go = await this.pushDialog("Are you sure you want to remove this entry?", {
                mode: "confirm",
                acceptText: "Remove",
            })
            if (!go) return
            this.$emit("delete", this.record)
        }
    },

    components: {
        PopUp,
        TaButton,
    }
}
</script>
