import Cookies from "js-cookie"
import Vue from "vue"
import Vuex from "vuex"
import axios from "axios"

Vue.use(Vuex)

const makeState = () => ({
    user: null,
    notifications: [],

    devices: {},  // id => Device
    details: {},  // id => DeviceDetail

    tracks:  {},   // id => Track
    records: {},   // trackId => [Record]

    prefetched: [],
})

export const getters = {
    userDevices: (state) => {
        const { user, devices } = state
        return Object.values(devices).filter(d => d.userId == user.id)
    },
}

export const mutations = {
    pushPrefetch(state, key) {
        state.prefetched.push(key)
    },

    popPrefetch(state, key) {
        const idx = state.prefetched.indexOf(key)
        if (idx != -1) state.prefetched.splice(idx, 1)
    },

    setCurrentUser(state, user) {
        state.user = user
    },

    pushNotification(state, notification) {
        state.notifications.push(notification)
    },

    removeNotification(state, notification) {
        const idx = state.notifications.indexOf(notification)
        if (idx != -1) state.notifications.splice(idx, 1)
    },

    setDevice(state, device) {
        Vue.set(state.devices, device.id, device)
    },

    unsetDevice(state, deviceId) {
        Vue.delete(state.devices, deviceId)
        Vue.delete(state.details, deviceId)
    },

    setDetail(state, { deviceId, detail }) {
        Vue.set(state.details, deviceId, detail)
    },

    // TODO Don't. Just have a updateDevice and have the backend return the updated entity
    lockDevice(state, deviceId) {
        state.devices[deviceId].lockedAt = new Date()
    },

    // TODO same
    unlockDevice(state, deviceId) {
        state.devices[deviceId].lockedAt = null
    },

    unset(state) {
        state.user = null
        state.devices = {}
        state.details = {}
    },

    "track/addRecord"(state, { trackId, record }) {
        if (trackId in state.records) {
            state.records[trackId].unshift(record)
        } else {
            Vue.set(state.records, trackId, [record])
        }
    },

    "track/deleteRecord"(state, { trackId, recordId }) {
        const records = state.records[trackId]
        if (!records) return
        const idx = records.findIndex(r => r.id == recordId)
        if (idx != -1) records.splice(idx, 1)
    },

    "track/setRecords"(state, { trackId, records }) {
        Vue.set(state.records, trackId, records)
    },

    "track/setRecord"(state, { trackId, record }) {
        const records = state.records[trackId]
        const oldRecord = records.find(r => r.id == record.id)
        Object.assign(oldRecord, record)
    },

    "track/setTrack"(state, track) {
        Vue.set(state.tracks, track.id, track)
    },

    "track/removeTrack"(state, trackId) {
        Vue.delete(state.tracks, trackId)
    }
}

export function makeActions() {
    return {
        comeBack({ commit }) {
            return axios.get("/me")
            .then(response => {
                const user = response.data.data
                commit("setCurrentUser", user)
            }, (error) => {
                if (error.response && error.response.status == 401) {
                    // It's fine
                } else {
                    throw error
                }
            })
        },

        login({ commit, dispatch }, { email, password }) {
            return axios.post("/login", { email, password })
                .then(response => {
                    const { user, token } = response.data.data
                    Cookies.set("TASESSION", token, {
                        sameSite: "strict",
                    })
                    commit("setCurrentUser", user)
                    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
                    return true
                }, error => dispatch("notifyOfError", error))
        },

        async logout({ commit }) {
            Cookies.remove("TASESSION")
            commit("unset")
            await axios.post("/logout")
        },

        register({ dispatch }, { email, password, fullName, sn, pin, sc1, sc2, sc3 }) {
            return axios.post("/register", { email, password, fullName, sn, pin, sc1, sc2, sc3 })
            .then(() => {
                dispatch("pushNotification", {
                    type: "success",
                    text: "You will receive a confirmation message!"
                })
                return true
            }, error => dispatch("notifyOfError", error))
        },

        remember({ dispatch }, { email }) {
            return axios.post("/remember", { email })
            .then(() => {
                dispatch("pushNotification", {
                    type: "success",
                    text: "Please check your email"
                })
                return true
            }, error => dispatch("notifyOfError", error))
        },

        reset({ dispatch }, { token, password }) {
            return axios.post("/reset", { token, password })
            .then(() => {
                dispatch("pushNotification", {
                    type: "success",
                    text: "Your password has been reset!"
                })
                return true
            }, error => dispatch("notifyOfError", error))
        },

        notifyOfError({ dispatch }, requestError) {
            const errors = requestError.response.data.errors || []
            dispatch("pushNotification", {
                type: "error",
                text: errors.join("\n")
            })
        },

        pushNotification({ commit }, { type="info", text }) {
            const notification = {
                timestamp: Date.now(),
                type,
                text,
            }
            commit("pushNotification", notification)
            setTimeout(() => {
                commit("removeNotification", notification)
            }, 5000)
        },

        dismissNotification({ commit }, notification) {
            commit("removeNotification", notification)
        },

        async refreshDevices({ commit }) {
            const response = await axios.get("/devices")
            const devices = response.data.data
            for (let device of devices) {
                commit("setDevice", device)
            }
        },

        async refreshDevice({ commit }, deviceId) {
            const response = await axios.get(`/devices/${deviceId}`)
            const device = response.data.data
            commit("setDevice", device)
        },

        async refreshDetail({ commit }, deviceId) {
            const response = await axios.get(`/devices/${deviceId}/extra`)
            const detail = response.data.data
            commit("setDetail", { deviceId, detail })
        },

        async refreshDeviceEvents({ state, commit }) {
            const response = await axios.get("/latest-events")
            const deviceEvents = response.data.data

            for (let [deviceId, events] of Object.entries(deviceEvents)) {
                commit("setDetail", {
                    deviceId,
                    detail: Object.assign({}, state.details[deviceId], { events })
                })
            }
        },

        addDevice({ dispatch }, { sn, pin, sc1, sc2, sc3 }) {
            return axios.post("/add-device", { sn, pin, sc1, sc2, sc3 })
                .then(() => {
                    dispatch("pushNotification", {
                        type: "success",
                        text: "Device has been added"
                    })
                    dispatch("refreshDevices")
                    return true
                }, error => {
                    dispatch("notifyOfError", error)
                    return false
                })
        },

        lockDevice({ commit, dispatch }, id) {
            return axios.post("/lock-device", { id })
            .then(() => {
                commit("lockDevice", id)
                dispatch("pushNotification", {
                    type: "success",
                    text: "Device has been locked"
                })
            }, error => dispatch("notifyOfError", error))
        },

        unlockDevice({ commit, dispatch }, id) {
            return axios.post("/unlock-device", { id })
            .then(() => {
                commit("unlockDevice", id)
                dispatch("pushNotification", {
                    type: "success",
                    text: "Device has been unlocked"
                })
            }, error => dispatch("notifyOfError", error))
        },

        unlinkDevice({ commit, dispatch }, id) {
            return axios.post("/unlink-device", { id })
            .then(() => {
                commit("unsetDevice", id)
                dispatch("pushNotification", {
                    type: "success",
                    text: "Device has been unlinked",
                })
            }, error => dispatch("notifyOfError", error))
        },

        editDeviceAlias({ commit, dispatch }, { deviceId, alias }) {
            return axios.post("/update-device", {
                deviceId,
                alias,
            })
            .then(response => {
                const device = response.data.data
                commit("setDevice", device)
                return true
            }, error => dispatch("notifyOfError", error))
        },

        "track/refresh"({ commit, dispatch }) {
            return axios.get("/tracks")
            .then(response => {
                const tracks = response.data.data
                for (let track of tracks) {
                    commit("track/setTrack", track)
                }
            }, error => dispatch("notifyOfError", error))
        },

        "track/refreshRecords"({ commit, dispatch }, trackId) {
            return axios.get(`/tracks/${trackId}/records`)
            .then(response => {
                const records = response.data.data
                commit("track/setRecords", { trackId, records })
                return records
            }, error => dispatch("notifyOfError", error))
        },

        "track/addRecord"({ commit, dispatch }, { trackId, entries }) {
            return axios.post(`/tracks/${trackId}/records`, { entries })
            .then(response => {
                const record = response.data.data
                commit("track/addRecord", { trackId, record })
                dispatch("pushNotification", {
                    type: "success",
                    text: "Your session has been recorded.",
                })
            }, error => dispatch("notifyOfError", error))
        },

        "track/deleteRecord"({ commit, dispatch }, { trackId, recordId }) {
            return axios.post(`/tracks/${trackId}/records/${recordId}/delete`)
            .then(() => {
                commit("track/deleteRecord", { trackId, recordId })
                dispatch("pushNotification", {
                    type: "success",
                    text: "Session deleted.",
                })
            }, error => dispatch("notifyOfError", error))
        },

        "track/addTrack"({ commit, dispatch }, name) {
            return axios.post("/tracks", { name })
            .then(response => {
                const track = response.data.data
                commit("track/setTrack", track)
                dispatch("pushNotification", {
                    type: "success",
                    text: "Track has been created.",
                })
                return track
            }, error => dispatch("notifyOfError", error))
        },

        "track/renameTrack"({ commit, dispatch }, { trackId, name }) {
            return axios.post(`/tracks/${trackId}`, { name })
            .then(response => {
                const track = response.data.data
                commit("track/setTrack", track)
                dispatch("pushNotification", {
                    type: "success",
                    text: "Track has been renamed.",
                })
                return track
            }, error => dispatch("notifyOfError", error))
        },

        "track/renameTrackEntry"({ commit, dispatch }, { trackId, recordId, name }) {
            return axios.post(`/tracks/${trackId}/records/${recordId}`, {
                name
            })
            .then(response => {
                const record = response.data.data
                commit("track/setRecord", { trackId, record })
            }, error => dispatch("notifyOfError", error))
        },

        "track/removeTrack"({ commit, dispatch }, trackId) {
            return axios.post(`/tracks/${trackId}/delete`)
            .then(() => {
                commit("track/removeTrack", trackId)
                dispatch("pushNotification", {
                    type: "success",
                    text: "Track has been deleted."
                })
            }, error => dispatch("notifyOfError", error))
        },

        pushPrefetch({ commit }, key) {
            commit("pushPrefetch", key)
        },

        popPrefetch({ state, commit }, key) {
            const rc = state.prefetched.indexOf(key) != -1
            commit("popPrefetch", key)
            return rc
        }
    }
}

export function createStore() {
    return new Vuex.Store({
        state: makeState(),
        getters,
        mutations,
        actions: makeActions()
    })
}
