<template>
    <input
        ref="input"
        class="rounded-none w-full outline-none block text-gray-800 py-2 border-gray-400 border-b bg-white"
        :value="value"
        @input="input"
    >
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            default: () => "",
        }
    },

    methods: {
        input(event) {
            this.$emit("input", event.target.value.trim())
        },

        focus() {
            this.$refs.input.focus()
        }
    }
}
</script>

<style scoped>
input {
     transition: border 0.25s, background-color 0.25s;
}

input[readonly], input[disabled] {
    @apply text-gray-500;
}

input:hover {
    @apply border-gray-600;
}

input:focus {
    @apply bg-gray-100 border-gray-600;
}
</style>
