<template>
    <form @submit.prevent="submit">
        <div class="mb-4">
            <div class="flex">
                <label class="label">
                    Serial number
                </label>
                <span
                    ref="snHelp"
                    tabindex="0"
                    class="text-sm cursor-pointer outline-none ml-auto"
                    @click="showSnHelp = !showSnHelp"
                >
                    <v-icon
                        scale="1.15"
                        name="info-circle"
                    />
                </span>
            </div>
            <input-text
                v-model="sn"
                placeholder="Serial number"
            />
            <input-hint
                v-if="$v.sn.$error"
                :hints="snHints"
            />
        </div>

        <div class="mb-4">
            <div class="flex">
                <label class="label">
                    Security codes
                </label>
                <span
                    ref="codesHelp"
                    tabindex="0"
                    class="text-sm cursor-pointer outline-none ml-auto"
                    @click="showCodesHelp = !showCodesHelp"
                >
                    <v-icon
                        scale="1.15"
                        name="info-circle"
                    />
                </span>
            </div>
            <div class="flex">
                <input-text
                    v-model="sc1"
                    placeholder="Code 1"
                />
                <input-text
                    v-model="sc2"
                    class="ml-2"
                    placeholder="Code 2"
                />
                <input-text
                    v-model="sc3"
                    class="ml-2"
                    placeholder="Code 3"
                />
            </div>
            <input-hint
                v-if="$v.sc1.$error || $v.sc2.$error || $v.sc3.$error"
                :hints="codesHints"
            />
        </div>

        <ta-button class="w-full">
            Add
        </ta-button>
        <pop-up
            v-if="showSnHelp"
            class="bg-white rounded p-3 text-sm shadow-lg"
            :reference="$refs.snHelp"
            @hide="showSnHelp=false"
        >
            You can find the serial number in the back of your device.
        </pop-up>

        <pop-up
            v-if="showCodesHelp"
            class="bg-white rounded text-sm p-3 shadow-lg"
            :reference="$refs.codesHelp"
            @hide="showCodesHelp=false"
        >
            You can find the security codes in your certificate of authenticity.
        </pop-up>
    </form>
</template>

<script>
import { required } from "vuelidate/lib/validators"

import InputText from "~/components/InputText"
import TaButton from "~/components/TaButton"
import InputHint from "~/components/InputHint"
import PopUp from "~/components/PopUp"

export default {
    props: {
        initialSn: {
            type: String,
            default: "",
        },
    },

    data() {
        return {
            sn: this.initialSn,
            sc1: "",
            sc2: "",
            sc3: "",

            showSnHelp: false,
            showCodesHelp: false,
        }
    },

    computed: {
        snHints() {
            return [
                !this.$v.sn.required && "SN is required",
            ]
        },

        codesHints() {
            return [
                !(this.$v.sc1.required && this.$v.sc2.required && this.$v.sc3.required) && "All security codes are required"
            ]
        }
    },

    validations: {
        sn:  { required },
        sc1: { required },
        sc2: { required },
        sc3: { required },
    },

    methods: {
        async submit() {
            this.$v.$touch()
            if (this.$v.$invalid) return // TODO flash

            this.$emit("add", {
                sn: this.sn,
                sc1: this.sc1,
                sc2: this.sc2,
                sc3: this.sc3,
            })
        }
    },

    components: {
        InputText,
        TaButton,
        InputHint,
        PopUp,
    }
}
</script>
