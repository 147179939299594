<template>
    <div>
        <ta-button @click="newUpdate">
            New update
        </ta-button>

        <div class="mt-3 overflow-x-auto">
            <table>
                <thead>
                    <tr>
                        <th>id</th>
                        <th>version</th>
                        <th>fileName</th>
                        <th>size</th>
                        <th>md5</th>
                        <th>forceful?</th>
                        <th>enabled?</th>
                        <th>createdAt</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="update in updates"
                        :key="update.id"
                    >
                        <td>{{ update.id }}</td>
                        <td>{{ update.version }}</td>
                        <td>
                            <a @click.prevent="download(update)">
                                {{ update.fileName }}
                            </a>
                        </td>
                        <td>{{ update.size }}</td>
                        <td>{{ update.md5Hash }}</td>
                        <td>{{ update.forceful }}</td>
                        <td>
                            <a @click.prevent="toggleUpdate(update)">
                                {{ update.enabled }}
                            </a>
                        </td>
                        <td>{{ update.createdAt }}</td>
                        <td class="flex">
                            <ta-button
                                class="flex-shrink-0"
                                theme="negative"
                                @click="editUpdate(update)"
                            >
                                <v-icon name="pencil-alt" />
                            </ta-button>
                            <ta-button
                                class="flex-shrink-0"
                                theme="negative"
                                @click="deleteUpdate(update)"
                            >
                                <v-icon name="regular/times-circle" />
                            </ta-button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import axios from "axios"
import urlJoin from "url-join"

import { download } from "~/lib/utils"

import TaButton from "~/components/TaButton"

import NewUpdateModal from "../components/NewUpdateModal"

export default {
    inject: ["pushModal", "pushDialog"],

    data() {
        return {
            updates: [],
        }
    },

    mounted() {
        this.refresh()
    },

    methods: {
        async refresh() {
            const response = await axios.get("/dev/updates")
            this.updates = response.data.data
        },

        newUpdate() {
            const pop = this.pushModal(NewUpdateModal, {
                on: {
                    done() {
                        pop()
                        this.refresh()
                    }
                }
            })
        },

        editUpdate() {
        },

        async deleteUpdate(update) {
            const go = await this.pushDialog("Are you sure?", { mode: "confirm" })
            if (!go) return

            await axios.post("/dev/delete-update", {
                id: update.id
            })
            this.refresh()
            this.$store.dispatch("pushNotification", {
                type: "success",
                text: "Update deleted"
            })
        },

        async toggleUpdate(update) {
            const response = await axios.post("/dev/toggle-update", {
                id: update.id
            })
            Object.assign(update, response.data.data)
            this.$store.dispatch("pushNotification", {
                type: "success",
                text: update.enabled ? "Update enabled" : "Update disabled"
            })
        },

        async download(update) {
            const response = await axios.get(`/dev/get-download-token/${update.blobName}`)
            const token = response.data.data

            // TODO weird
            download(urlJoin(axios.defaults.baseURL, "/dev/download", token))
        }
    },

    components: {
        TaButton
    }
}
</script>

<style scoped>
th {
    @apply p-1;
}

td {
    @apply p-1 border-r;
}

td:first-child {
    @apply border-l;
}

tr {
    @apply border-b;
}

tr:hover {
    @apply bg-gray-300;
}
</style>
