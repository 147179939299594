<template>
    <div class="mt-12 position-relative">
        <header class="bg-white flex shadow-md fixed inset-x-0 top-0 z-10">
            <ta-button
                theme="negative"
                class="mr-auto hover:bg-gray-300"
                @click="toggleMenu"
            >
                <v-icon
                    scale="1.25"
                    name="bars"
                />
            </ta-button>

            <div class="inset-0 absolute py-2 pointer-events-none">
                <img
                    class="h-full w-auto m-auto"
                    :src="logoUrl"
                >
            </div>
        </header>

        <slot />

        <side-panel
            body-class="pt-12 flex flex-col overflow-y-auto"
            :show="showSidePanel"
            @hide="showSidePanel=false"
        >
            <div class="px-4 py-3 border-b">
                Hi,
                <span class="font-semibold">
                    {{ user.name }}
                </span>!
            </div>

            <div class="flex flex-col border-b">
                <router-link
                    to="/rack"
                    active-class="font-bold"
                    class="px-4 py-3 hover:bg-gray-300"
                >
                    My Rack
                </router-link>

                <router-link
                    to="/tracks"
                    active-class="font-bold"
                    class="px-4 py-3 hover:bg-gray-300"
                >
                    My Tracks
                </router-link>

                <router-link
                    to="/profile"
                    active-class="font-bold"
                    class="px-4 py-3 hover:bg-gray-300"
                >
                    Settings
                </router-link>

                <router-link
                    to="/support"
                    active-class="font-bold"
                    class="px-4 py-3 hover:bg-gray-300"
                >
                    <span class="nudge-left">Support</span>
                </router-link>

                <router-link
                    v-if="user.isAdmin"
                    to="/admin"
                    active-class="font-bold"
                    class="px-4 py-3 hover:bg-gray-300"
                >
                    <span class="nudge-left">Admin</span>
                </router-link>
            </div>

            <div class="flex flex-col border-b">
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://tierra.audio/blog/"
                    class="px-4 py-3 hover:bg-gray-300"
                >
                    <v-icon
                        class="mr-2 text-xs"
                        name="external-link-alt"
                    />
                    Blog
                </a>
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://store.tierra.audio/"
                    class="px-4 py-3 hover:bg-gray-300"
                >
                    <v-icon
                        class="mr-2 text-xs"
                        name="external-link-alt"
                    />
                    Online store
                </a>
            </div>

            <social-bar class="border-t border-b mt-auto text-center" />

            <love-bar class="border-b text-center" />

            <ta-button
                theme="negative"
                class="text-left hover:bg-gray-300"
                @click="logout"
            >
                <v-icon
                    class="mr-2"
                    name="sign-out-alt"
                />
                Sign out
            </ta-button>
        </side-panel>
    </div>
</template>

<script>
import { mapState } from "vuex"

import SidePanel from "~/components/SidePanel"
import TaButton from "~/components/TaButton"

import SocialBar from "~/components/SocialBar"
import LoveBar from "~/components/LoveBar"

import logoUrl from "~/images/logo_header.png"

export default {
    props: {
        bodyClass: {
            type: null,
            default: () => "",
        }
    },

    data() {
        return {
            showSidePanel: false,

            logoUrl
        }
    },

    computed: {
        ...mapState(["user"]),
    },

    methods: {
        toggleMenu() {
            this.showSidePanel = !this.showSidePanel
        },

        logout() {
            this.$store.dispatch("logout")
            this.$router.push("/login")
        },
    },

    components: {
        SidePanel,
        TaButton,
        SocialBar,
        LoveBar,
    }
}
</script>

<style>
.nudge-left {
    position: relative;
    right: .2em;
}
</style>
