<template>
    <transition
        name="fade"
        appear
        @after-leave="afterLeave"
    >
        <component
            :is="tag"
            ref="popup"
            class="z-10"
        >
            <slot />
        </component>
    </transition>
</template>

<script>
import { createPopper } from "@popperjs/core"

export default {
    props: {
        reference: {
            type: null,
            required: true
        },
        placement: {
            type: String,
            default: "bottom-end"
        },
        fixed: {
            type: Boolean,
            default: false
        },
        tag: {
            type: String,
            default: "div",
        },
        separation: {
            type: Number,
            default: 10 // px
        },
        slide: {
            type: Number,
            default: 0 // px
        }
    },

    mounted() {
        this.$nextTick(() => {
            const ref   = this.reference
            const popup = this.$refs.popup

			this._popper = createPopper(ref, popup, {
                placement: this.placement,
				strategy: this.fixed ? "fixed" : "absolute",
                modifiers: [{
                    name: "offset",
                    options: {
                        offset: [this.slide, this.separation]
                    }
                }]
			})

            // TODO Otherwise the hide element fires in the same click that showed this.
            // Don't know why!
            setTimeout(() => {
                this._clickListener = event => {
                    const inside = this.$el == event.target
                        || this.reference == event.target
                        || this.reference.contains(event.target)
                        || this.$el.contains(event.target)
                    if (!inside) {
                        this.$emit("hide")
                    }
                }
                document.addEventListener("click", this._clickListener)
            })
        })
    },

    beforeDestroy() {
        document.removeEventListener("click", this._clickListener)
    },

    methods: {
        afterLeave() {
            // Are we positive this is always called?
            this._popper.destroy()
        }
    }
}
</script>
