<template>
    <input
        ref="input"
        class="rounded-none w-full outline-none block text-gray-800 py-2 border-gray-400 border-b bg-white"
        type="number"
        :value="value"
        @input="input"
    >
</template>

<script>
export default {
    props: {
        value: {
            type: Number,
            default: () => 0,
        }
    },

    methods: {
        input(event) {
            this.$emit("input", Number(event.currentTarget.value))
        },

        focus() {
            this.$refs.input.focus()
        }
    }
}
</script>
