<template>
    <div class="min-h-screen mx-auto max-w-sm px-3 flex flex-col justify-center">
        <img
            class="mb-10"
            :src="logoUrl"
        >

        <h1 class="text-center">
            PASSWORD RESET
        </h1>

        <form
            class="mt-4"
            @submit.prevent="submit"
        >
            <div class="mb-4">
                <label class="label">
                    Password
                </label>
                <input-text
                    v-model="password"
                    type="password"
                    placeholder="Password"
                />
                <input-hint
                    v-if="$v.password.$error"
                    :hints="passwordHints"
                />
            </div>

            <div class="pb-4">
                <label class="label">
                    Repeat password
                </label>
                <input-text
                    v-model="passwordConfirmation"
                    type="password"
                    placeholder="Repeat password"
                />
                <input-hint
                    v-if="$v.passwordConfirmation.$error"
                    :hints="passwordConfirmationHints"
                />
            </div>

            <div class="flex items-baseline">
                <router-link
                    to="/login"
                    class="item-start w-full text-center"
                >
                    Go back
                </router-link>
                <ta-button class="w-full">
                    Reset
                </ta-button>
            </div>
        </form>
    </div>
</template>

<script>
import {
    required,
    minLength,
    sameAs,
} from "vuelidate/lib/validators"

import InputText from "~/components/InputText"
import TaButton from "~/components/TaButton"
import InputHint from "~/components/InputHint"

import logoUrl from "~/images/favic.gif"

export default {
    props: {
        token: {
            type: String,
            required: true,
        }
    },

    data() {
        return {
            password: "",
            passwordConfirmation: "",

            logoUrl,
        }
    },

    computed: {
        passwordHints() {
            return [
                !this.$v.password.required && "Password is required",
                !this.$v.password.minLength && `Password must have at least ${this.$v.password.$params.minLength.min} characters`
            ]
        },

        passwordConfirmationHints() {
            return [
                !this.$v.passwordConfirmation.sameAs && "Passwords don't match",
            ]
        }
    },

    validations: {
        password: {
            required,
            minLength: minLength(10)
        },
        passwordConfirmation: {
            sameAsPassword: sameAs("password")
        }
    },

    methods: {
        submit() {
            this.$v.$touch()
            if (this.$v.$invalid) return // TODO flash

            this.$store.dispatch("reset", {
                token: this.token,
                password: this.password,
            })
            .then(() => {
                this.$router.push("/")
            })
        }
    },

    components: {
        InputText,
        TaButton,
        InputHint,
    }
}
</script>
