<template>
    <div 
        v-if="!value"
        class="relative border h-10"
        @click="upload"
    >
        <div 
            style="z-index:-1"
            class="absolute h-full bg-blue-300" 
            :style="style"
        />
    </div>
    <div 
        v-else
        class="h-10 flex items-center" 
    >
        {{ value.fileName }} ({{ value.size }})
        <ta-button 
            theme="negative"
            @click="remove" 
        >
            <v-icon name="regular/times-circle" />
        </ta-button>
    </div>
</template>

<script>
import axios from "axios"

import { requestFiles } from "~/lib/utils"

import TaButton from "~/components/TaButton"

export default {
    props: {
        value: {
            type: Object,
            default: () => null,
        }
    },

    data() {
        return {
            progress: 30, // [-1, 100] // -1: ready
        }
    },

    computed: {
        style() {
            return {
                width: `${this.progress}%`
            }
        }
    },

    methods: {
        async upload() {
            const files = await requestFiles()
            if (files.length == 0) return

            const data = new FormData()
            data.append("file", files[0])

            this.progress = -1
            const response = await axios.post("/dev/tmp-upload", data, {
                onUploadProgress: event => {
                    this.progress = Math.round((event.loaded * 100) / event.total)
                }
            })
            this.progress = 100

            const tmpFile = response.data.data
            this.$emit("input", tmpFile)
        },

        remove() {
            this.$emit("input", null)
        }
    },

    components: {
        TaButton
    }
}
</script>
