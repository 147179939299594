<template>
    <default-layout>
        <div class="p-6 w-full max-w-3xl mx-auto min-h-screen">
            <header class="flex mb-5 items-center justify-between">
                <h1>My Tracks</h1>
                <ta-button
                    :disabled="validDevices.length == 0"
                    theme="outline"
                    @click="addTrack"
                >
                    Add track
                    <v-icon name="plus" />
                </ta-button>
            </header>

            <router-link
                v-for="(track, id) in tracks"
                :key="id"
                class="block mtsp-4"
                :to="trackLocation(track)"
            >
                <track-item :track="track" />
            </router-link>

            <div
                v-if="validDevices.length == 0 && numTracks == 0"
                class="text-gray-500 border-l-2 rounded p-3 max-w-sm m-auto flex items-center mt-2"
            >
                <v-icon
                    scale="3"
                    class="flex-shrink-0 mr-3"
                    name="shopping-cart"
                />
                <p>
                    You have no devices! You need something to record with!
                    <br>
                    Why don't you check out our
                    <a
                        class="text-gray-900"
                        rel="noopener noreferrer"
                        target="_blank"
                        href="https://store.tierra.audio"
                    >store</a>?
                </p>
            </div>
            <div
                v-else-if="numTracks == 0"
                class="text-gray-500 border-l-2 rounded p-3 max-w-sm m-auto flex items-center"
            >
                <v-icon
                    scale="3"
                    class="flex-shrink-0 mr-3"
                    name="microphone-alt"
                />
                <p>
                    You have no tracks!
                    <br>
                    You can use them to store your devices setup.
                </p>
            </div>
        </div>

        <main-footer class="mt-10" />
    </default-layout>
</template>

<script>
import { mapState, mapGetters } from "vuex"

import { getControlsForPN } from "~/lib/deviceControls"

import { urlSlug } from "~/lib/utils"

import DefaultLayout from "~/layouts/DefaultLayout"
import TaButton from "~/components/TaButton"
import AddTrackModal from "~/components/AddTrackModal"
import TrackItem from "~/components/TrackItem"
import MainFooter from "~/components/MainFooter"

export default {
    inject: ["pushModal"],

    computed: {
        ...mapState(["tracks"]),

        ...mapGetters(["userDevices"]),

        numTracks() {
            return Object.keys(this.tracks).length
        },

        validDevices() {
            return this.userDevices.filter(device => {
                const hasControls = !!getControlsForPN(device.model)
                return hasControls
            })
        }
    },

    serverPrefetch() {
        return Promise.all([
            this.$store.dispatch("track/refresh"),
            this.$store.dispatch("refreshDevices"),
        ])
    },

    mounted() {
        this.$store.dispatch("track/refresh")
        this.$store.dispatch("refreshDevices")
    },

    methods: {
        trackLocation(track) {
            const id = track.id
            const slug = urlSlug(track.name)
            return  `/tracks/${id}/${slug}`
        },

        async addTrack() {
            const pop = this.pushModal(AddTrackModal, {
                on: {
                    add: async (name) => {
                        const track = await this.$store.dispatch("track/addTrack", name)
                        pop()
                        this.$router.push(this.trackLocation(track))
                    },
                    close: () => {
                        pop()
                    }
                }
            })
        }
    },

    components: {
        DefaultLayout,
        TaButton,
        TrackItem,
        MainFooter,
    }
}
</script>
