<template>
    <div>
        <header class="flex items-center">
            <h2 class="ml-1 font-fancy text-gray-700">
                {{ name }}
            </h2>
            <ta-button
                ref="actions"
                class="ml-auto self-start text-gray-600"
                theme="negative"
                @click="togglePopUp"
            >
                <v-icon name="ellipsis-h" />
            </ta-button>
        </header>
        <div class="overflow-auto flex">
            <div
                v-for="control in controls"
                :ref="control.name"
                :key="control.name"
                class="flex flex-col justify-center"
            >
                <template v-if="!Array.isArray(control)">
                    <knob-control
                        v-if="control.type == 'knob'"
                        :value="value[control.name]"
                        :control="control"
                        @input="v => handleInput(control.name, v)"
                    />
                    <button-control
                        v-else-if="control.type == 'button'"
                        :value="value[control.name]"
                        :title="control.title"
                        @input="v => handleInput(control.name, v)"
                    />
                    <FancyLabel
                        v-else-if="control.type == 'label'"
                        class="font-fancy text-gray-800 flex items-center justify-center font-semibold h-16"
                        :label="control.title"
                    />
                </template>
                <template v-else-if="Array.isArray(control)">
                    <template v-for="c in control">
                        <knob-control
                            v-if="c.type == 'knob'"
                            :key="c.name"
                            :value="value[c.name]"
                            :control="c"
                            @input="v => handleInput(c.name, v)"
                        />
                        <button-control
                            v-else-if="c.type == 'button'"
                            :key="c.name"
                            :value="value[c.name]"
                            :title="c.title"
                            @input="v => handleInput(c.name, v)"
                        />
                        <ChannelControl
                            v-else-if="c.type == 'channel'"
                            :key="c.name"
                            :control="c"
                            :value="value[c.name]"
                            @input="v => handleInput(c.name, v)"
                        />
                        <FancyLabel
                            v-else-if="c.type == 'label'"
                            :key="c.name"
                            class="font-fancy text-gray-800 flex items-center justify-center font-semibold h-16"
                            :label="c.title"
                        />
                    </template>
                </template>
            </div>
        </div>

        <pop-up
            v-if="popUpVisible"
            class="bg-white rounded flex flex-col px-2 py-4 shadow-lg"
            :reference="$refs.actions.$el"
            @hide="popUpVisible=false"
        >
            <ta-button
                theme="negative"
                class="text-left"
                @click="renameDevice"
            >
                <v-icon
                    class="mr-1"
                    name="pencil-alt"
                />
                Rename
            </ta-button>
            <ta-button
                theme="negative"
                class="text-left"
                @click="reorderDevice"
            >
                <v-icon
                    class="mr-1"
                    name="sort"
                />
                Reorder
            </ta-button>
            <ta-button
                theme="negative"
                class="mt-2 text-left"
                @click="removeDevice"
            >
                <v-icon
                    class="mr-1"
                    name="times"
                />
                Remove
            </ta-button>
        </pop-up>
    </div>
</template>

<script>
import cloneDeep from "lodash/cloneDeep"

import KnobControl from "~/components/KnobControl"
import ButtonControl from "~/components/ButtonControl"
import PopUp from "~/components/PopUp"
import TaButton from "~/components/TaButton"
import RenameModal from "~/components/RenameModal"
import FancyLabel from "~/components/FancyLabel"
import ReorderModal from "~/components/ReorderModal"
import ChannelControl from "~/components/ChannelControl"

export default {
    inject: ["pushModal"],

    props: {
        name: {
            type: String,
            required: true,
        },
        controls: {
            type: Array,
            required: true,
        },
        value: {
            type: Object,
            required: true,
        },
    },

    data() {
        return {
            popUpVisible: false,
        }
    },

    methods: {
        handleInput(controlName, value) {
            const values = cloneDeep(this.value)
            values[controlName] = value
            this.$emit("input", values)
        },

        removeDevice() {
            this.$emit("remove")
        },

        togglePopUp() {
            this.popUpVisible = !this.popUpVisible
        },

        renameDevice() {
            const pop = this.pushModal(RenameModal, {
                props: {
                    initialName: this.name,
                },
                on: {
                    rename: async name => {
                        this.$emit("name-input", name)
                        pop()
                    },
                    close: () => pop()
                }
            })
        },

        reorderDevice() {
            const pop = this.pushModal(ReorderModal, {
                on: {
                    move: dir => {
                        this.$emit("move", dir)
                        pop()
                    },
                    close: () => {
                        pop()
                    }
                }
            })
        }
    },

    components: {
        KnobControl,
        ButtonControl,
        PopUp,
        TaButton,
        FancyLabel,
        ChannelControl,
    }
}
</script>
