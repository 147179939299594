<template>
    <div
        class="flex flex-col items-center py-1 px-3 cursor-pointer hover:bg-gray-200 rounded overflow-hidden"
        style="transition: background-color 0.25s"
        :class="controlClass"
        @click="click"
    >
        <div class="h-30 w-30 relative select-none">
            <StaticKnob
                ref="svg"
                class="absolute center w-full h-full"
                :style="knobStyle"
                @mousedown.native="mousedown"
            />

            <light
                v-for="i in control.steps"
                :key="i"
                class="absolute h-1 w-1 fill-current pointer-events-none"
                :style="lightStyle(i, 30)"
                :value="lightValue(i)"
                style="transition: color 0.25s"
            />

            <FancyLabel
                v-for="i in control.steps"
                v-once
                :key="`text-${i}`"
                class="absolute font-fancy text-sm text-gray-800 font-semibold whitespace-no-wrap pointer-events-none"
                :style="lightStyle(i, 44)"
                :label="control.labels[i-1]"
            />
        </div>

        <FancyLabel
            v-once
            ref="title"
            class="font-fancy text-gray-800 font-semibold whitespace-no-wrap"
            :label="control.title"
        />

        <pop-up
            v-if="selected"
            :reference="$refs.title.$el"
            class="bg-white shadow-lg p-4 rounded overflow-hidden w-full max-w-xs mx-4"
            fixed
            @hide="selected = false"
        >
            <touch-slider
                class="h-16"
                :value="value"
                :min="control.min"
                :max="control.max"
                :origin="control.origin"
                :logarithmic="control.logarithmic"
                @dragging="dragging = true"
                @dragend="dragging = false"
                @input="handleInput"
            />
        </pop-up>
    </div>
</template>

<script>
import { lerp, clamp, mround } from "~/lib/utils"

import Light from "./Light"

import PopUp from "~/components/PopUp"
import TouchSlider from "~/components/TouchSlider"
import FancyLabel from "~/components/FancyLabel"

import StaticKnob from "~/components/StaticKnob"


export default {
    props: {
        /**
        * {
        *     steps: Number
        *     max: Number,
        *     min: Number,
        *     origin: Number
        *     step: Number    // Restrict movement to increments `step` in value.
        *     from: Number    // degrees
        *     to: Number      // degrees
        *     discrete: Boolean, // Only lit current light
        * }
        */
        control: {
            type: Object,
            required: true,
        },
        value: {
            type: Number,
            required: true,
        },
    },

    data() {
        return {
            selected: false,
            dragging: false,
            preventClick: false,
        }
    },

    computed: {
        controlClass() {
            if (this.selected) {
                return [ "bg-gray-200" ]
            } else {
                return []
            }
        },

        normalizedValue() {
            return this.control.logarithmic ? Math.log(this.value) : this.value
        },

        normalizedMax() {
            return this.control.logarithmic ? Math.log(this.control.max) : this.control.max
        },

        normalizedMin() {
            return this.control.logarithmic ? Math.log(this.control.min) : this.control.min
        },

        normalizedOrigin() {
            return this.control.logarithmic ? this.control.origin > 0 ? Math.log(this.control.origin) : 0 : this.control.origin
        },

        knobStyle() {
            const t = (this.normalizedValue - this.normalizedMin) / (this.normalizedMax - this.normalizedMin)
            const rotation = lerp(this.control.from, this.control.to, t)
            const style = {
                left: "50%",
                top: "50%",
                transform: `translate(-50%, -50%) rotate(${rotation}deg)`,
            }
            if (!this.dragging) {
                style.transition = "transform 0.25s"
            }
            return style
        },
    },

    methods: {
        lightValue(i) {
            const o = this.normalizedOrigin
            const v = this.normalizedValue
            const t = (i - 1) / (this.control.steps - 1)
            const dt = 1 / (this.control.steps - 1)
            const s  = lerp(this.normalizedMin, this.normalizedMax, t)
            const s0 = lerp(this.normalizedMin, this.normalizedMax, t - 0.5 * dt)
            const s1 = lerp(this.normalizedMin, this.normalizedMax, t + 0.5 * dt)

            if (this.control.discrete) {
                return v >= s0 && v <= s1
            } else {
                if (v >= o) {
                    return s >= o && v >= s
                } else {
                    return s <= o && v <= s
                }
            }
        },

        lightStyle(index, r) {
            let tetha = (this.control.to - this.control.from) / (this.control.steps - 1)
            tetha = Math.PI * tetha / 180

            const from = Math.PI * this.control.from / 180

            const angle = from + tetha * (index - 1)
            const dx = 50 + r * Math.sin(-angle)
            const dy = 50 + r * Math.cos(-angle)

            return {
                left: `${dx}%`,
                top: `${dy}%`,
                transform: `translate(-50%, -50%)`
            }
        },

        mousedown(event) {
            const y0 = event.clientY
            const v0 = this.normalizedValue

            this.preventClick = false

            const mousemove = event => {
                this.dragging = true
                this.preventClick = true
                const y1 = event.clientY

                const k = 1 * (this.normalizedMax - this.normalizedMin) / (this.control.to - this.control.from)

                let value = clamp(this.normalizedMin, this.normalizedMax, v0 + k * (y0 - y1))
                if (this.control.logarithmic) {
                    value = Math.exp(value)
                }
                if (this.control.step) {
                    value = mround(value, this.control.step)
                }
                this.$emit("input", value)
            }

            const mouseup = () => {
                this.dragging = false
                document.removeEventListener("mousemove", mousemove)
                document.removeEventListener("mouseup", mouseup)
            }
            document.addEventListener("mousemove", mousemove)
            document.addEventListener("mouseup", mouseup)
        },

        handleInput(value) {
            if (this.control.step) {
                value = mround(value, this.control.step)
            }
            this.$emit("input", value)
        },

        click() {
            if (this.preventClick) return

            this.selected = true
        },
    },

    components: {
        Light,
        PopUp,
        TouchSlider,
        FancyLabel,
        StaticKnob,
    }
}
</script>
