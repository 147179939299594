<template>
    <div>
        <desktop-genuine-view
            v-show="status == 'done' && $mq.lg"
            :sn="sn"
            :device="device"
        />
        <mobile-genuine-view
            v-show="status == 'done' && !$mq.lg"
            :sn="sn"
            :device="device"
        />
    </div>
</template>

<script>
import axios from "axios"

import MobileGenuineView from "./MobileGenuineView"
import DesktopGenuineView from "./DesktopGenuineView"

export default {
    props: {
        sn: {
            type: String,
            required: true,
        }
    },

    data() {
        return {
            status: "loading", // loading | done | error
            device: null,
        }
    },

    async mounted() {
        const { sn } = this
        try {
            const response = await axios.get(`/public-device/${sn}`)
            this.device = response.data.device
            this.status = "done"
        } catch (error) {
            this.status = "error"
        }
    },

    components: {
        MobileGenuineView,
        DesktopGenuineView,
    }
}
</script>
