<template>
    <div class="dialog-box rounded max-w-xs shadow bg-white p-3">
        <p class="p-4">
            {{ text }}
        </p>

        <div class="flex justify-end">
            <ta-button
                v-if="mode == 'confirm'"
                class="mr-3"
                theme="negative"
                @click="cancel"
            >
                {{ cancelText }}
            </ta-button>

            <ta-button @click="accept">
                {{ acceptText }}
            </ta-button>
        </div>
    </div>
</template>

<script>
import TaButton from "~/components/TaButton"

export default {
    props: {
        text: {
            type: String,
            required: true,
        },
        mode: {
            type: String,
            required: true,
            validator: mode => ["alert", "confirm"].includes(mode)
        },
        acceptText: {
            type: String,
            required: true,
        },
        cancelText: {
            type: String,
            required: true,
        }
    },

    methods: {
        accept() {
            this.$emit("done", true)
        },

        cancel() {
            this.$emit("done", false)
        }
    },

    components: {
        TaButton
    }
}
</script>

<style scoped>
.dialog-box {
    width: calc(100% - 2rem);
}
</style>
