<template>
    <form
        class="rounded shadow bg-white p-3"
        @submit.prevent="rename"
    >
        <div class="p-4">
            <label class="label">
                Name
            </label>
            <input-text v-model.trim="name" />
            <input-hint
                v-if="$v.name.$error"
                :hints="nameHints"
            />
        </div>

        <div class="flex justify-end">
            <ta-button
                theme="negative"
                type="button"
                @click="cancel"
            >
                Cancel
            </ta-button>
            <ta-button class="ml-3">
                Rename
            </ta-button>
        </div>
    </form>
</template>

<script>
import { required } from "vuelidate/lib/validators"

import InputText from "~/components/InputText"
import InputHint from "~/components/InputHint"
import TaButton from "~/components/TaButton"

export default {
    props: {
        initialName: {
            type: String,
            default: "",
        }
    },

    data() {
        return {
            name: this.initialName
        }
    },

    computed: {
        nameHints() {
            return [
                !this.$v.name.required && "Name is required",
            ]
        }
    },

    validations: {
        name: {
            required,
        }
    },

    methods: {
        cancel() {
            this.$emit("close")
        },

        async rename() {
            this.$v.$touch()
            if (this.$v.$invalid) return
            this.$emit("rename", this.name)
        }
    },

    components: {
        InputHint,
        InputText,
        TaButton,
    }
}
</script>
