<template>
    <div
        class="px-4 py-2 text-sm font-normal"
        :class="wrapperClass"
    >
        HANDMADE WITH
        <v-icon
            class="text-red-500"
            name="heart"
        />
        IN MADRID
    </div>
</template>

<script>
export default {
    props: {
        negative: {
            type: Boolean,
            default: false,
        }
    },

    computed: {
        wrapperClass() {
            return {
                "text-gray-400": this.negative,
            }
        }
    }
}
</script>
