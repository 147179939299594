<template>
    <div>
        <h2 class="mb-4">
            Appears in
        </h2>
        <ul>
            <li
                v-for="track in tracks"
                :key="track.id"
            >
                <router-link :to="trackLocation(track)">
                    {{ track.name }}
                </router-link>
            </li>
        </ul>
    </div>
</template>

<script>
import { urlSlug } from "~/lib/utils"

export default {
    props: {
        tracks: {
            type: Array,
            required: true,
        }
    },

    methods: {
        trackLocation(track) {
            const id = track.id
            const slug = urlSlug(track.name)
            return  `/tracks/${id}/${slug}`
        },
    }
}
</script>
