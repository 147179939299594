<script>
const scales = {
    rabbit: 1.4,
    turtle: 1.4,
}
export default {
    props: {
        label: {
            type: String,
            required: true,
        }
    },

    render(h) {
        let els = []
        let inIcon = false
        let acc = ""
        for (let i = 0; i < this.label.length; i++) {
            const char = this.label[i]

            if (char == ":") {
                if (inIcon) {
                    els.push(h("v-icon", { props: { name: acc, scale: scales[acc] || 1 } }))
                } else {
                    els.push(acc)
                }
                acc = ""
                inIcon = !inIcon
            } else {
                acc += char
            }
        }

        if (acc.length > 0) {
            if (inIcon) {
                els.push(h("v-icon", { props: { name: acc, scale: scales[acc] || 1 } }))
            } else {
                els.push(acc)
            }
        }

        return h("span", els)
    }
}
</script>
