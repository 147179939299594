<template>
    <div>
        <ta-button @click="newDevice">
            New device
        </ta-button>

        <div class="overflow-x-auto mt-3">
            <table>
                <thead>
                    <tr>
                        <th>id</th>
                        <th>sn</th>
                        <th>secureId</th>
                        <th>MAC</th>
                        <th>alias</th>
                        <th>PIN Code</th>
                        <th>model</th>
                        <th>lockedAt</th>
                        <th>userId</th>
                        <th>linkedAt</th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="device in devices"
                        :key="device.id"
                    >
                        <td>{{ device.id }}</td>
                        <td>{{ device.sn }}</td>
                        <td>{{ device.secureId }}</td>
                        <td>{{ device.mac }}</td>
                        <td>{{ device.alias }}</td>
                        <td>{{ device.pinCode }}</td>
                        <td>{{ device.model }}</td>
                        <td>{{ device.lockedAt }}</td>
                        <td>{{ device.userId }}</td>
                        <td>
                            <template v-if="device.linkedAt">
                                {{ device.linkedAt | iso8601 | wordsago }}
                            </template>
                            <template v-else>
                                -
                            </template>
                        </td>
                        <td class="flex">
                            <ta-button
                                class="flex-shrink-0"
                                theme="negative"
                                @click="editDevice(device)"
                            >
                                <v-icon name="pencil-alt" />
                            </ta-button>
                            <ta-button
                                class="flex-shrink-0"
                                theme="negative"
                                @click="deleteDevice(device)"
                            >
                                <v-icon name="regular/times-circle" />
                            </ta-button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import axios from "axios"

import TaButton from "~/components/TaButton"

import EditDeviceModal from "../components/EditDeviceModal"
import NewDeviceModal from "../components/NewDeviceModal"

export default {
    inject: ["pushModal", "pushDialog"],

    data() {
        return {
            devices: [],
        }
    },

    async mounted() {
        const response = await axios.get("/dev/devices")
        this.devices = response.data.data
    },

    methods: {
        newDevice() {
            const pop = this.pushModal(NewDeviceModal, {
                on: {
                    done() {
                        pop()
                        this.refresh()
                    }
                }
            })
        },

        editDevice(device) {
            const pop = this.pushModal(EditDeviceModal, {
                props: { device },
                on: {
                    done() {
                        pop()
                        this.refresh()
                    }
                }
            })
        },

        async deleteDevice(device) {
            const go = await this.pushDialog("Are you sure?", { mode: "confirm" })
            if (!go) return

            try {
                await axios.post("/dev/delete-device", {
                    id: device.id
                })
                this.refresh()
                this.$store.dispatch("pushNotification", {
                    type: "success",
                    text: "Device deleted"
                })
            } catch(e) {
                this.$store.dispatch("pushNotification", {
                    type: "error",
                    text: e.toString()
                })
            }
        },
    },

    components: {
        TaButton
    }
}
</script>

<style scoped>
th {
    @apply p-1;
}

td {
    @apply p-1 border-r;
}

td:first-child {
    @apply border-l;
}

tr {
    @apply border-b;
}

tr:hover {
    @apply bg-gray-300;
}
</style>
