<template>
    <form @submit.prevent="submit">
        <div class="mb-4">
            <label class="label">
                Email
            </label>
            <input-text
                :value="user.email"
                readonly
            />
        </div>

        <div class="mb-4">
            <label class="label">
                New password
            </label>
            <input-text
                v-model.trim="password"
                type="password"
            />
            <input-hint
                v-if="$v.password.$error"
                :hints="passwordHints"
            />
        </div>

        <div class="mb-4">
            <label class="label">
                Repeat password
            </label>
            <input-text
                v-model.trim="passwordConfirmation"
                type="password"
            />
            <input-hint
                v-if="$v.passwordConfirmation.$error"
                :hints="passwordConfirmationHints"
            />
        </div>

        <div class="mb-4">
            <label class="label">
                Current password
            </label>
            <input-text
                v-model.trim="currentPassword"
                type="password"
            />
            <input-hint
                v-if="$v.currentPassword.$error"
                :hints="currentPasswordHints"
            />
        </div>

        <ta-button class="mt-4">
            Update
        </ta-button>
    </form>
</template>

<script>
import {
    requiredIf,
    minLength,
    sameAs
} from "vuelidate/lib/validators"
import { mapState } from "vuex"
import axios from "axios"

import InputText from "~/components/InputText"
import InputHint from "~/components/InputHint"
import TaButton from "~/components/TaButton"

export default {
    data() {
        return {
            password: "",
            passwordConfirmation: "",
            currentPassword: "",
        }
    },

    computed: {
        ...mapState(["user"]),

        passwordHints() {
            return [
                !this.$v.password.minLength && `Password must have at least ${this.$v.password.$params.minLength.min} characters`
            ]
        },

        passwordConfirmationHints() {
            return [
                !this.$v.passwordConfirmation.sameAs && "Passwords don't match",
            ]
        },

        currentPasswordHints() {
            return [
                !this.$v.currentPassword.required && "Current password is required",
            ]
        },
    },

    validations: {
        password: {
            minLength: minLength(10)
        },
        passwordConfirmation: {
            required: requiredIf(function() { return !!this.password }),
            sameAsPassword: sameAs("password"),
        },
        currentPassword: {
            required: requiredIf(function() { return !!this.password }),
        },
    },

    methods: {
        async submit() {
            this.$v.$touch()
            if (this.$v.$invalid) return // TODO flash

            await axios.post("/update-profile", {
                currentPassword: this.currentPassword,
                newPassword: this.password,
            })
            this.password = ""
            this.passwordConfirmation = ""
            this.currentPassword = ""
            this.$store.dispatch("pushNotification", {
                type: "success",
                text: "Your preferences have been updated",
            })
        },
    },

    components: {
        InputText,
        TaButton,
        InputHint,
    }
}
</script>
