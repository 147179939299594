<template>
    <div>
        <h2 class="mb-4">
            Recent activity
        </h2>

        <ul>
            <li
                v-for="event in cookedEvents"
                :key="event.id"
            >
                {{ event.when | wordsago }} ago | {{ event.text }}
            </li>
        </ul>

        <span
            v-if="events.length > 10"
            tabindex="0"
            class="cursor-pointer"
            @click="seeMoreEvents"
        >
            See more
        </span>
    </div>
</template>

<script>
import RecentActivityModal from "~/components/RecentActivityModal"

import { cookDeviceEvent } from "~/lib/utils"

export default {
    inject: ["pushModal"],

    props: {
        events: {
            type: Array,
            required: true
        },
        lastPulseDate: {
            type: Date,
            default: () => null,
        }
    },

    computed: {
        cookedEvents() {
            const events = this.events.map(cookDeviceEvent)

            if (this.lastPulseDate) {
                events.unshift({
                    id: "last-pulse",
                    when: this.lastPulseDate,
                    text: "Device last pulse"
                })
            }

            return events.slice(0, 10)
        },
    },

    methods: {
        seeMoreEvents() {
            this.pushModal(RecentActivityModal, {
                props: {
                    events: this.events,
                    lastPulseDate: this.lastPulseDate,
                }
            })
        }
    }
}
</script>
