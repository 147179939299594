<template>
    <div class="dialog-box rounded shadow bg-white relative">
        <ta-button
            class="absolute top-0 right-0"
            theme="negative"
            @click="$emit('hide')"
        >
            <v-icon name="times" />
        </ta-button>

        <h1 class="text-center mb-6">
            {{ name }}
        </h1>

        <big-line-chart
            :y-unit="yUnit"
            :data="data"
        />
    </div>
</template>

<script>
import TaButton from "~/components/TaButton"
import BigLineChart from "~/components/BigLineChart"

export default {
    props: {
        name: {
            type: String,
            required: true,
        },
        data: {
            type: Array,
            required: true
        },
        yUnit: {
            type: String,
            default: () => {},
        }
    },

    components: {
        TaButton,
        BigLineChart,
    }
}
</script>

<style scoped>
.dialog-box {
    width: calc(100% - 2rem);
}
</style>
