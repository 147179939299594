import slugify from "slugify"
import chunk from "lodash/chunk"

/**
 * Open a file request window. Resolves to an Array of
 * selected Files.
 */
export function requestFiles({ accept="", multiple=false }={}) {
    return new Promise(resolve => {
        const input = document.createElement("input")

        input.setAttribute("type", "file")
        if (multiple) input.setAttribute("multiple", true)
        if (accept) input.setAttribute("accept", accept)
        input.style.opacity = "0"

        input.addEventListener("change", () => {
            const files = Array.from(input.files || [])
            resolve(files)
            document.body.removeChild(input)
        })

        document.body.appendChild(input)
        input.click()
    })
}

export function download(url) {
    const a = document.createElement("a")
    a.href = url
    a.download = ""
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
}

export function cookDeviceEvent(event, extraProps={}) {
    const cookedEvent = {
        id: event.id,
        when: new Date(event.createdAt),
        ...extraProps,
    }

    switch (event.type) {
        case "device-unlocked":
            cookedEvent.text = "Device was unlocked"
            break

        case "device-locked":
            cookedEvent.text = "Device was locked"
            break

        case "engine-updated":
            cookedEvent.text = `Engine updated to version v${event.data.to}`
            break

        case "first-pulse":
            cookedEvent.text = "Device first sent a pulse"
            break

        default:
            throw new Error(`Unkown event type: ${event.type}`)
    }

    return cookedEvent
}

export function urlSlug(str) {
    return slugify(str, {
        remove: /:/,
        lower: true,
    })
}

export function sqrtChunk(array) {
    const chunkSize = Math.ceil(Math.sqrt(array.length))
    return chunk(array, chunkSize)
}

export const lerp  = (a, b, t) => a + t * (b - a)
export const clamp = (a, b, t) => Math.max(a, Math.min(t, b))
export const mround = (x, m) => Math.round(x / m) * m
export const deg2rad = x => Math.PI * x / 180
export const rad2deg = x => 180 * x / Math.PI

export function times(n, cb) {
    const results = []
    for (let i = 0; i < n; i++) {
        results.push(cb(i))
    }
    return results
}
