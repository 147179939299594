<template>
    <div class="dialog-box max-w-3xl shadow rounded bg-white p-6">
        <form @submit.prevent="submit">
            <div>
                <label>
                    <input 
                        v-model="forceful" 
                        type="checkbox" 
                    >
                    forceful?
                </label>
            </div>

            <div>
                <label>Description</label>
                <text-area v-model="description" />
            </div>

            <div>
                <label>Fixes</label>
                <input-number v-model="fixes" />
            </div>

            <div>
                <label>News</label>
                <input-number v-model="news" />
            </div>

            <div>
                <label>File</label>
                <upload-area v-model="file" />
            </div>

            <div class="mt-3">
                <ta-button>
                    Save
                </ta-button>
            </div>
        </form>
    </div>
</template>

<script>
import axios from "axios"

import TaButton from "~/components/TaButton"
import UploadArea from "~/components/UploadArea"
import InputNumber from "~/components/InputNumber"
import TextArea from "~/components/TextArea"

export default {
    data() {
        return {
            forceful: false,
            file: null,
            description: "",
            fixes: 0,
            news: 0,
        }
    },

    methods: {
        async submit() {
            await axios.post("/dev/new-update", {
                forceful: this.forceful,
                file: this.file,
                description: this.description,
                fixes: this.fixes,
                news: this.news,
            })
            this.$store.dispatch("pushNotification", {
                type: "success",
                text: "Update created",
            })
            this.$emit("done")
        }
    },

    components: {
        UploadArea,
        TaButton,
        InputNumber,
        TextArea,
    }
}
</script>

<style scoped>
.dialog-box {
    width: calc(100% - 2rem);
}
</style>
