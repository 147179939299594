<template>
    <div>
        <h2 class="mb-4">
            Information
        </h2>

        <div class="flex py-2">
            <div class="flex text-xs flex-col w-1/2">
                <span class="font-semibold">Serial number</span>
                <span>{{ device.sn }}</span>
            </div>
            <div class="flex text-xs flex-col w-1/2 ml-2">
                <span class="font-semibold">Model</span>
                <span>{{ device.model }}</span>
            </div>
        </div>
        <div class="flex py-2">
            <div
                v-if="false"
                class="flex text-xs flex-col w-1/2"
            >
                <span class="font-semibold">Engine version</span>
                <span>v{{ detail ? detail.engineVersion : "-" }}</span>
            </div>
            <div
                v-if="false"
                class="flex text-xs flex-col w-1/2 ml-2"
            >
                <span class="font-semibold">Engine version</span>
                <span>v{{ detail ? detail.engineVersion : "-" }}</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        device: {
            type:Object,
            required: true,
        },
        detail: {
            type: Object,
            required: true,
        }
    }
}
</script>
