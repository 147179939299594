<template>
    <div>
        <h2 class="mb-4">
            Last known location
        </h2>

        <address-map
            :latitude="geolocation.latitude"
            :longitude="geolocation.longitude"
            :name="name"
        />
    </div>
</template>

<script>
import AddressMap from "~/components/AddressMap"

export default {
    props: {
        geolocation: {
            type: Object,
            required: true
        }
    },

    computed: {
        name() {
            const { city, country } = this.geolocation
            return `${city}, ${country}`
        }
    },

    components: {
        AddressMap,
    }
}
</script>
