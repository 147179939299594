<template>
    <div v-if="device">
        <h1 class="text-center">
            CERTIFICATE OF QUALITY AND AUTHENTICITY
        </h1>

        <toast-notification
            v-if="false"
            class="mx-4 mtsp-4"
            icon="hands-helping"
            type="error"
        >
            This device has been lost or stolen! Please
            <a
                class="font-semibold"
                href="mailto:team@tierra.audio"
            >
                contact
            </a>
            <span class="whitespace-no-wrap">
                TIERRA Audio
            </span>
            if you have any information.
        </toast-notification>

        <toast-notification
            v-if="device.isDemo"
            class="mx-4 mtsp-4"
            icon="store-alt"
            type="warning"
        >
            This device is for demonstration purposes only.
        </toast-notification>

        <toast-notification
            v-if="user && !device.isDemo && !device.linkedAt"
            class="mx-4 mtsp-4"
            icon="plus-circle"
            type="info"
        >
            This device is unregistered. Click
            <router-link
                class="font-semibold"
                :to="`/rack/?add=${device.sn}`"
            >
                here
            </router-link>to add it to your rack.
        </toast-notification>

        <toast-notification
            v-if="!user && !device.isDemo && !device.linkedAt"
            class="mx-4 mtsp-4"
            icon="sign-in-alt"
            type="info"
        >
            Click
            <router-link
                class="font-semibold"
                :to="`/register?with=${device.sn}`"
            >
                here
            </router-link>
            to register with this device.
        </toast-notification>

        <div class="p-4">
            <p class="mb-2">
                This device has been handcrafted and conforms with the rigorous quality requisites of
                <span class="whitespace-no-wrap">TIERRA Audio</span>.
            </p>

            <div class="mb-2">
                <label class="text-xs font-semibold">
                    Serial number
                </label>
                <div class="text-lg text-gray-700">
                    {{ device.sn }}
                </div>
            </div>
            <div
                v-if="device.groupCode"
                class="mb-2"
            >
                <label class="text-xs font-semibold">
                    Match group
                </label>
                <div class="text-lg text-gray-700">
                    {{ device.groupCode }}
                </div>
            </div>
            <div class="mb-2">
                <label class="text-xs font-semibold">
                    Model
                </label>
                <div class="text-lg text-gray-700">
                    {{ device.model }}
                </div>
            </div>
            <div class="mb-2">
                <label class="text-xs font-semibold">
                    Manufacture date
                </label>
                <div class="text-lg text-gray-700">
                    {{ device.doneAt | iso8601 | date }}
                </div>
            </div>
            <div>
                <label class="text-xs font-semibold">
                    Quality measurements
                </label>
                <table class="text-gray-700 w-full">
                    <tbody>
                        <tr
                            v-for="(measurement, index) in device.measurements.filter(m => !m.type)"
                            :key="measurement.ref"
                            :class="(index < device.measurements.length - 1) && 'border-b'"
                        >
                            <td>
                                {{ measurement.name }}
                            </td>
                            <td class="text-right">
                                {{ measurement.value }} {{ measurement.unit }}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="flex flex-col">
                    <LineChart
                        v-for="m in device.measurements.filter(m => m.type == 'plot')"
                        :key="m.ref"
                        :chart-data="chartDataForMeasurement(m)"
                        :options="optionsForMeasurement(m)"
                    />
                </div>
            </div>
        </div>
    </div>
    <div
        v-else
        :class="!$mq.lg && 'px-4 pt-12 max-w-xl mx-auto'"
    >
        <div class="text-xl text-red-700 mb-2">
            {{ sn }}
        </div>
        <p>
            This serial number does not correspond to an authentic
            <span class="whitespace-no-wrap">TIERRA Audio</span>
            product. If you have purchased this device, please get in
            <a
                class="font-semibold"
                href="mailto:team@tierra.audio"
            >
                contact
            </a>
            with us. We take counterfeiting very seriously.
        </p>
    </div>
</template>

<script>
import { mapState } from "vuex"

import ToastNotification from "~/components/ToastNotification"

export default {
    props: {
        sn: {
            type: String,
            required: true,
        },
        device: {
            type: Object,
            default: null,
        }
    },

    computed: {
        ...mapState(["user", "devices"])
    },

    methods: {
        chartDataForMeasurement(m) {
            const [xs, yss] = m.value

            const colors = [
                "rgba(255, 0, 0, 0.1)",
                "rgba(0, 0, 255, 0.1)",
            ]

            return {
                datasets: yss.map((ys, idx) => ({
                    label: idx == 0 ? "Left" : "Right",
                    borderColor: colors[idx],
                    backgroundColor: colors[idx],
                    data: ys
                })),
                labels: xs,
            }
        },

        optionsForMeasurement(m) {
            const [_, yss] = m.value
            const [x_unit, y_unit] = m.unit
            return {
                title: {
                    display: true,
                    text: m.name,
                },

                legend: {
                    display: yss.length > 1,
                },

                scales: {
                    yAxes: [{
                        ticks: {
                            callback(value, _index, _values) {
                                return `${value} ${y_unit}`
                            }
                        }
                    }],
                    xAxes: [{
                        ticks: {
                            callback(value, _index, _values) {
                                return `${value} ${x_unit}`
                            }
                        }
                    }]
                },

                tooltips: {
                    callbacks: {
                        label(tooltipItem, _data) {
                            return `${tooltipItem.yLabel} ${y_unit}`
                        }
                    }
                }
            }
        }
    },

    components: {
        ToastNotification,
        LineChart: () => import("~/components/LineChart")
    }
}
</script>
