<template>
    <div
        class="px-4 py-2"
        :class="wrapperClass"
    >
        <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://twitter.com/tierraaudio"
        >
            <v-icon name="brands/twitter" />
        </a>
        <a
            class="ml-2"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.facebook.com/tierraaudio"
        >
            <v-icon name="brands/facebook" />
        </a>
        <a
            class="ml-2"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/tierraaudio/"
        >
            <v-icon name="brands/instagram" />
        </a>
        <a
            class="ml-2"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.youtube.com/channel/UC2R_UmlCMNI1CstwlvbV5SA"
        >
            <v-icon name="brands/youtube" />
        </a>
    </div>
</template>

<script>
export default {
    props: {
        negative: {
            type: Boolean,
            default: false,
        }
    },

    computed: {
        wrapperClass() {
            return {
                "text-gray-400": this.negative,
            }
        }
    }
}
</script>
