import "./client-public-path"
import Cookies from "js-cookie"
import axios from "axios"
import { ApplicationInsights } from "@microsoft/applicationinsights-web"

import { createApp } from "./main"

if (window.__INITIAL_STATE__.enableAppInsights) {
    const appInsights = new ApplicationInsights({ config: {
        instrumentationKey: window.__INITIAL_STATE__.appInsightsInstrumentationKey,
        enableAutoRouteTracking: true,
    } })
    appInsights.loadAppInsights()
    const telemetryInitializer = envelope => {
        envelope.tags["ai.cloud.role"] = "ClientWeb"
        //envelope.tags["ai.cloud.roleInstance"] = "your role instance";
    }
    appInsights.addTelemetryInitializer(telemetryInitializer)
    appInsights.trackPageView()
}

const { app, store } = createApp()

if (window.__INITIAL_STATE__.store) {
    store.replaceState(window.__INITIAL_STATE__.store)
}

const TASESSION = Cookies.get("TASESSION")
if (TASESSION) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${TASESSION}`
}

axios.defaults.baseURL = window.__INITIAL_STATE__.apiBaseUrl

window.TA = app
app.$mount('#app', true)
