<template>
    <router-link
        class="block rounded overflow-hidden mb-3"
        :to="location"
    >
        <div
            class="bg-cover block bg-center flex flex-col justify-between"
            :style="coverStyle"
        >
            <header class="bg-alpha px-3 py-1 text-white">
                <h2 class="font-thin">
                    {{ device.alias }}
                </h2>
            </header>
            <div
                v-if="device.status || device.lastPulseDate"
                class="bg-alpha px-2 py-1 flex text-xs text-gray-300 items-baseline"
            >
                <span
                    v-if="device.status"
                    class="uppercase text-sm"
                >
                    <v-icon
                        class="text-red-500"
                        name="heartbeat"
                    />
                    <strong>{{ device.status }}</strong>
                </span>
                <span
                    v-if="device.lastPulseDate"
                    class="ml-auto"
                >
                    {{ device.lastPulseDate | iso8601 | wordsago }} ago
                </span>
            </div>
        </div>
    </router-link>
</template>

<script>
import lavaImgUrl from "~/images/lava.jpg"
import calimaImgUrl from "~/images/calima.jpg"
import borealImgUrl from "~/images/boreal.jpg"
import gravityImgUrl from "~/images/gravity.jpg"
import icicleImgUrl from "~/images/icicle.jpg"
import canyonImgUrl from "~/images/canyon.jpg"
import flavoursImgUrl from "~/images/flavours.jpg"
import bambooImgUrl from "~/images/bamboo.jpg"
import blackBambooImgUrl from "~/images/black_bamboo.jpg"
import whiteBambooImgUrl from "~/images/white_bamboo.jpg"

const PN = {
    lava:    "TA-DEV-001",
    calima:  "TA-DEV-002",
    boreal:  "TA-DEV-003",
    gravity: "TA-DEV-004",
    icicle:  "TA-DEV-005",
    canyon:  "TA-DEV-006",

    flavours:   "TA-FLV-",

    bamboo:         "TA-BMB-001",
    whiteBamboo:    "TA-BMB-002",
    blackBamboo:    "TA-BMB-003",
}
export default {
    props: {
        device: {
            type: Object,
            required: true,
        }
    },

    computed: {
        bgImgUrl() {
            if (this.device.model.startsWith(PN.lava)) {
                return lavaImgUrl
            } else if (this.device.model.startsWith(PN.calima)) {
                return calimaImgUrl
            } else if (this.device.model.startsWith(PN.boreal)) {
                return borealImgUrl
            } else if (this.device.model.startsWith(PN.gravity)) {
                return gravityImgUrl
            } else if (this.device.model.startsWith(PN.icicle)) {
                return icicleImgUrl
            } else if (this.device.model.startsWith(PN.canyon)) {
                return canyonImgUrl
            } else if (this.device.model.startsWith(PN.flavours)) {
                return flavoursImgUrl
            } else if (this.device.model.startsWith(PN.bamboo)) {
                return bambooImgUrl
            } else if (this.device.model.startsWith(PN.whiteBamboo)) {
                return whiteBambooImgUrl
            } else if (this.device.model.startsWith(PN.blackBambooImgUrl)) {
                return blackBambooImgUrl
            } else {
                throw new Error(`Unknown model ${this.device.model}`)
            }
        },

        coverStyle() {
            return {
                minHeight: `150px`,
                backgroundImage: `radial-gradient(ellipse at center, #7d7e7d33 0%, #0e0e0ecc 100%), url('${this.bgImgUrl}')`
            }
        },

        location() {
            return `/device/${this.device.id}`
        }
    }
}
</script>

<style scoped>
.bg-alpha {
    background-color: #0008;
}
</style>
