<template>
    <line-chart
        :chart-data="chartData"
        :options="options"
    />
</template>

<script>
export default {
    props: {
        data: {
            type: Array,
            required: true,
        },
        yUnit: {
            type: String,
            default: () => "",
        }
    },

    computed: {
        chartData() {
            return {
                datasets: [{
                    data: this.data,

                    lineTension: 0.5,

                    backgroundColor: "#0000",

                    borderColor: "#222",
                    borderWidth: 1,

                    pointBorderColor: "#000",
                    pointBackgroundColor: "#0F0",
                    pointBorderWidth: 1,
                    pointRadius: 0,
                }]
            }
        },

        options() {
            return {
                responsive: true,
                maintainAspectRatio: false,
                animation: {
                    duration: 0 // general animation time
                },
                hover: {
                    animationDuration: 0 // duration of animations when hovering an item
                },
                responsiveAnimationDuration: 0, // animation duration after a resize

                tooltips: {
                    enabled: false,
                },

                legend: {
                    display: false,
                },

                scales: {
                    xAxes: [{
                        type: "time",
                        time: {
                            round: "second",
                        },
                        ticks: {
                            display: true,
                            fontSize: 16,
                        },
                        gridLines: {
                            display: true,
                            drawBorder: true,
                            drawTicks: true,
                        },
                    }],

                    yAxes: [{
                        ticks: {
                            display: true,
                            suggestedMin: 0,
                            suggestedMax: 50,
                            fontSize: 16,
                            callback: val => `${val} ${this.yUnit}`,
                        },
                        gridLines: {
                            display: true,
                            drawBorder: true,
                            drawTicks: true,
                        },
                    }]
                }
            }
        }
    },

    components: {
        LineChart: () => import("~/components/LineChart")
    }
}
</script>
