<template>
    <form
        class="rounded shadow bg-white p-3"
        @submit.prevent="rename"
    >
        <div class="p-4">
            <label class="label">
                Alias
            </label>
            <input-text v-model.trim="alias" />
            <input-hint
                v-if="$v.alias.$error"
                :hints="aliasHints"
            />
        </div>

        <div class="flex justify-end">
            <ta-button
                theme="negative"
                type="button"
                @click="cancel"
            >
                Cancel
            </ta-button>
            <ta-button class="ml-3">
                Rename
            </ta-button>
        </div>
    </form>
</template>

<script>
import { required } from "vuelidate/lib/validators"

import InputText from "~/components/InputText"
import InputHint from "~/components/InputHint"
import TaButton from "~/components/TaButton"

export default {
    props: {
        device: {
            type: Object,
            required: true
        }
    },

    created() {
        this.alias = this.device.alias
    },

    data() {
        return {
            alias: "",
        }
    },

    computed: {
        aliasHints() {
            return [
                !this.$v.alias.required && "Alias is required",
            ]
        }
    },

    validations: {
        alias: {
            required,
        }
    },

    methods: {
        cancel() {
            this.$emit("close")
        },

        async rename() {
            this.$v.$touch()
            if (this.$v.$invalid) return

            await this.$store.dispatch("editDeviceAlias", {
                deviceId: this.device.id,
                alias: this.alias,
            })
            this.$emit("close")
        }
    },

    components: {
        InputHint,
        InputText,
        TaButton,
    }
}
</script>
