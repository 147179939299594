<template>
    <default-layout v-if="track">
        <div class="p-6 min-h-screen">
            <header class="flex items-center mb-4">
                <router-link
                    to="/tracks"
                    class="mr-3"
                >
                    <v-icon
                        name="arrow-left"
                        scale="1.5"
                    />
                </router-link>

                <h1>
                    {{ track.name }}
                </h1>

                <ta-button
                    ref="actions"
                    class="ml-auto text-gray-600"
                    theme="negative"
                    @click="togglePopUp"
                >
                    <v-icon name="ellipsis-v" />
                </ta-button>
            </header>

            <div class="flex border-b mb-4">
                <tab-button
                    :active="currentTab == 'setup'"
                    class="w-full text-center"
                    @click="goTab('setup')"
                >
                    Setup
                </tab-button>
                <tab-button
                    :active="currentTab == 'history'"
                    class="w-full text-center"
                    @click="goTab('history')"
                >
                    History
                </tab-button>
            </div>

            <template v-if="currentTab == 'setup'">
                <div class="flex mb-4 justify-end">
                    <ta-button
                        ref="deviceButton"
                        :disabled="availableDevices == 0"
                        class="mlsp-4"
                        theme="outline"
                        @click="devicePopUpVisible = !devicePopUpVisible"
                    >
                        <v-icon name="plus" />
                        Add device
                    </ta-button>
                    <ta-button
                        class="mlsp-4"
                        theme="outline"
                        :disabled="addRecordDisabled"
                        @click="addRecord"
                    >
                        <v-icon name="regular/save" />
                        Record session
                    </ta-button>
                </div>

                <transition-group name="flip-list">
                    <device-front
                        v-for="entry in scratchRecord"
                        :key="entry.deviceId"
                        class="mtsp-6 bg-white"
                        :name="entry.name"
                        :controls="getControls(devices[entry.deviceId].model)"
                        :value="entry.values"
                        @input="value => handleInput(entry, value)"
                        @name-input="name => handleNameInput(entry, name)"
                        @remove="removeDevice(entry.deviceId)"
                        @move="dir => handleMove(entry, dir)"
                    />
                </transition-group>

                <pop-up
                    v-if="devicePopUpVisible"
                    :reference="$refs.deviceButton.$el"
                    class="bg-white rounded flex flex-col py-2 shadow-lg"
                    @hide="devicePopUpVisible=false"
                >
                    <div
                        v-for="device in availableDevices"
                        :key="device.id"
                        class="flex flex-col px-4 py-1 cursor-pointer hover:bg-gray-200"
                        @click="selectDevice(device)"
                    >
                        <span>
                            {{ device.alias }}
                        </span>
                        <span class="text-xs">
                            {{ device.sn }}
                        </span>
                    </div>
                </pop-up>
            </template>
            <div
                v-else-if="currentTab == 'history'"
                class="max-w-3xl mx-auto"
            >
                <track-history-entry
                    v-for="(record, index) in records"
                    :key="record.id"
                    class="border-b"
                    :class="index == 0 && 'border-t'"
                    :record="record"
                    @select="restoreRecord(record)"
                    @delete="deleteRecord(record)"
                    @rename="name => renameTrackEntry(record, name)"
                />
            </div>
        </div>

        <pop-up
            v-if="popUpVisible"
            class="bg-white rounded flex flex-col px-2 py-4 shadow-lg"
            :reference="$refs.actions.$el"
            @hide="popUpVisible=false"
        >
            <ta-button
                theme="negative"
                class="text-left"
                @click="renameTrack"
            >
                <v-icon
                    class="mr-1"
                    name="pencil-alt"
                />
                Rename
            </ta-button>
            <ta-button
                theme="negative"
                class="mt-2 text-left"
                @click="removeTrack"
            >
                <v-icon
                    class="mr-1"
                    name="times"
                />
                Remove
            </ta-button>
        </pop-up>
        <main-footer class="mt-10" />
    </default-layout>
</template>

<script>
import cloneDeep from "lodash/cloneDeep"
import flatten from "lodash/flatten"

import DefaultLayout from "~/layouts/DefaultLayout"

import TabButton from "~/components/TabButton"
import TaButton from "~/components/TaButton"
import DeviceFront from "~/components/DeviceFront"
import PopUp from "~/components/PopUp"
import RenameModal from "~/components/RenameModal"
import MainFooter from "~/components/MainFooter"
import TrackHistoryEntry from "~/components/TrackHistoryEntry"

import { getControlsForPN } from "~/lib/deviceControls"

export default {
    inject: ["pushModal", "pushDialog"],

    props: {
        trackId: {
            type: String,
            required: true,
        },
        trackSlug: {
            type: String,
            default: "",
        }
    },

    data() {
        return {
            currentTab: "setup",
            scratchRecord: [],
            popUpVisible: false,
            devicePopUpVisible: false,
            addRecordDisabled: false,
        }
    },

    computed: {
        track() {
            return this.$store.state.tracks[this.trackId]
        },

        records() {
            return this.$store.state.records[this.trackId]
        },

        lastRecord() {
            return this.records[0]
        },

        devices() {
            return this.$store.state.devices
        },

        availableDevices() {
            return this.$store.getters.userDevices.filter(device => {
                const inUse = this.scratchRecord.some(entry => entry.deviceId == device.id)
                const hasControls = !!getControlsForPN(device.model)
                return !inUse && hasControls
            })
        },
    },

    async serverPrefetch() {
        await Promise.all([
            this.$store.dispatch("track/refresh"),
            this.$store.dispatch("track/refreshRecords", this.trackId).then(records => {
                const deviceIds = new Set()
                for (let record of records) {
                    for (let entry of record.entries) {
                        deviceIds.add(entry.deviceId)
                    }
                }
                return Promise.all(Array.from(deviceIds).map(id => this.$store.dispatch("refreshDevice", id)))
            })
        ])
        this.$store.dispatch("pushPrefetch", "track")
    },

    async mounted() {
        if (!await this.$store.dispatch("popPrefetch", "track")) {
            await Promise.all([
                this.$store.dispatch("track/refresh"),
                this.$store.dispatch("track/refreshRecords", this.trackId).then(records => {
                    const deviceIds = new Set()
                    for (let record of records) {
                        for (let entry of record.entries) {
                            deviceIds.add(entry.deviceId)
                        }
                    }
                    return Promise.all(Array.from(deviceIds).map(id => this.$store.dispatch("refreshDevice", id)))
                })
            ])
        }

        if (this.lastRecord) {
            this.scratchRecord = cloneDeep(this.lastRecord.entries)
        }
    },

    methods: {
        handleInput(entry, values) {
            entry.values = values
        },

        handleNameInput(entry, name) {
            entry.name = name
        },

        getControls(pn) {
            return getControlsForPN(pn)
        },

        selectDevice(userDevice) {
            const entry = {
                name: userDevice.alias,
                deviceId: userDevice.id,
                values: {},
            }

            const controls = getControlsForPN(userDevice.model)

            for (let control of flatten(controls)) {
                switch (control.type) {
                    case "knob":
                        entry.values[control.name] = control.default || 0
                        break

                    case "button":
                        entry.values[control.name] = control.default || false
                        break

                    case "channel":
                        entry.values[control.name] = control.default || "red"
                        break

                    case "label":
                        break

                    default:
                        throw new Error(`Unknown control type: ${control.type}`)
                }
            }

            this.scratchRecord.push(entry)
            this.devicePopUpVisible = false
        },

        removeDevice(deviceId) {
            const idx = this.scratchRecord.findIndex(e => e.deviceId == deviceId)
            this.scratchRecord.splice(idx, 1)
        },

        addRecord() {
            this.$store.dispatch("track/addRecord", {
                trackId: this.trackId,
                entries: this.scratchRecord,
            })
            this.addRecordDisabled = true
            window.setTimeout(() => {
                this.addRecordDisabled = false
            }, 1000)
        },

        restoreRecord(record) {
            this.scratchRecord = cloneDeep(record.entries)
            this.goTab("setup")
        },

        deleteRecord(record) {
            this.$store.dispatch("track/deleteRecord", {
                trackId: this.trackId,
                recordId: record.id,
            })
        },

        async removeTrack() {
            const go = await this.pushDialog("Are you sure you want to remove this track?", {
                mode: "confirm",
                acceptText: "Remove",
            })
            if (!go) return
            await this.$store.dispatch("track/removeTrack", this.trackId)
            this.$router.push(`/tracks`)
        },

        togglePopUp() {
            this.popUpVisible = !this.popUpVisible
        },

        renameTrackEntry(record, name) {
            this.$store.dispatch("track/renameTrackEntry", {
                trackId: record.trackId,
                recordId: record.id,
                name
            })
        },

        renameTrack() {
            const pop = this.pushModal(RenameModal, {
                props: {
                    initialName: this.track.name,
                },
                on: {
                    rename: async name => {
                        await this.$store.dispatch("track/renameTrack", {
                            trackId: this.trackId,
                            name
                        })
                        pop()
                    },
                    close: () => pop()
                }
            })
        },

        handleMove(entry, direction) {
            const index = this.scratchRecord.indexOf(entry)
            const len = this.scratchRecord.length
            const from = index
            const to = direction == "up" ? index - 1 : index + 1
            if (to < 0) return
            if (to > len-1) return

            this.$swap(this.scratchRecord, from, to)
        },

        goTab(name) {
            this.currentTab = name
            this.devicePopUpVisible = false
        }
    },

    components: {
        DefaultLayout,
        TaButton,
        DeviceFront,
        PopUp,
        MainFooter,
        TabButton,
        TrackHistoryEntry,
    }
}
</script>

<style>
.flip-list-move {
    transition: transform 1s;
}
</style>
