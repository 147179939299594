<template>
    <form @submit.prevent="submit">
        <div class="mb-4">
            <label class="label">
                Type
            </label>
            <input-select
                v-model="pn"
                :options="pns"
            />
        </div>
        <div class="mb-4">
            <label class="label">
                Serial number
            </label>
            <input-number
                v-model="seq"
                placeholder="Serial number"
            />
        </div>
        <ta-button class="w-full">
            Add
        </ta-button>
    </form>
</template>

<script>
import axios from "axios"

import InputSelect from "~/components/InputSelect"
import InputNumber from "~/components/InputNumber"
import TaButton from "~/components/TaButton"

export default {
    data() {
        return {
            pns: [
                { name: "Truffle (TA-FLV-001-001)", value: "TA-FLV-001-001" },
                { name: "Cocoa (TA-FLV-002-001)", value: "TA-FLV-002-001" },
                { name: "Mint (TA-FLV-003-001)", value: "TA-FLV-003-001" },
                { name: "Chilli (TA-FLV-004-001)", value: "TA-FLV-004-001" },
                { name: "Vanilla (TA-FLV-005-001)", value: "TA-FLV-005-001" },
                { name: "Pepper (TA-FLV-006-001)", value: "TA-FLV-006-001" },
                { name: "Salt (TA-FLV-007-001)", value: "TA-FLV-007-001" },
            ],
            pn: "TA-FLV-001-001",
            seq: 1,
        }
    },

    methods: {
        async submit() {
            axios.post("/translate-flavour-sn", {
                pn: this.pn,
                seq: this.seq,
            })
            .then(response => {
                const { sn, securityCodes } = response.data

                this.$emit("add", {
                    sn:  sn,
                    sc1: securityCodes[0],
                    sc2: securityCodes[1],
                    sc3: securityCodes[2],
                })
            }, error => this.$store.dispatch("notifyOfError", error))
        }
    },

    components: {
        InputSelect,
        InputNumber,
        TaButton,
    }
}
</script>
