<template>
    <div class="relative">
        <select
            v-model="valueProxy"
            class="block appearance-none w-full bg-white border hover:border-gray-600 pl-4 py-3 pr-8 outline-none"
        >
            <option
                v-for="option in options"
                :key="option.value"
                :value="option.value"
            >
                {{ option.name }}
            </option>
        </select>
        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-3 text-gray-700">
            <v-icon
                class="nudge"
                name="chevron-down"
            />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        options: {
            type: Array,
            required: true
        },
        value: {
            type: null,
            required: true
        }
    },

    computed: {
        valueProxy: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit("input", value)
            }
        },
    }
}
</script>

<style scoped>
select {
    transition: border 0.25s, background-color 0.25s;
}
.nudge {
    top: 0.06rem
}
</style>
