import { times } from "~/lib/utils"

// Lava
const taDev001002 = [{
    name: "gain",
    title: "gain",
    type: "knob",
    min: 15,
    max: 70,
    from: 30,
    to: 330,
    origin: 0,
    steps: 12,
    step: 5,
    default: 47.5,
    discrete: false,
    labels: ["15 dB", "20", "25", "30", "35", "40", "45", "50", "55", "60", "65", "70 dB"]
}, [{
    type: "button",
    name: "phantom",
    title: "48 v",
}, {
    type: "button",
    name: "hiz",
    title: "hi-z",
}], {
    name: "output",
    title: "output",
    type: "knob",
    min: 0,
    max: 1,
    from: 30,
    to: 330,
    origin: 0,
    steps: 11,
    step: 0,
    default: 0,
    discrete: false,
    labels: ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"]
}, [{
    type: "button",
    name: "phase",
    title: "phase",
}, {
    type: "button",
    name: "pad",
    title: "pad",
}]]

// Calima
const taDev002002 = [{
    name: "gain",
    title: "gain",
    type: "knob",
    min: 28,
    max: 72,
    from: 30,
    to: 330,
    origin: 0,
    steps: 12,
    step: 4,
    default: 47.5,
    discrete: false,
    labels: ["28 dB", "32", "36", "40", "44", "48", "52", "56", "60", "64", "68", "72 dB"]
}, [{
    type: "button",
    name: "phantom",
    title: "48 v",
}], {
    name: "output",
    title: "output",
    type: "knob",
    min: 0,
    max: 1,
    from: 30,
    to: 330,
    origin: 0.5,
    steps: 11,
    step: 0,
    default: 0,
    discrete: false,
    labels: ["-10 dB", "-8", "-6", "-4", "-2", "0", "+1", "+2", "+3", "+4", "+5 dB"]
}, [{
    type: "button",
    name: "phase",
    title: "phase",
}, {
    type: "button",
    name: "pad",
    title: "pad",
}]]

// Boreal
const taDev003002 = [{
    name: "input",
    title: "input",
    type: "knob",
    min: 0,
    max: 1,
    default: 0.5,
    from: 30,
    to: 330,
    origin: 0,
    steps: 11,
    step: 0,
    discrete: false,
    labels: ["∞dB", "50", "40", "35", "30", "25", "20", "15", "10", "5", "0dB"]
}, {
    name: "ratio",
    title: "ratio",
    type: "knob",
    min: 0,
    max: 1,
    default: 1/4,
    from: 130,
    to: 230,
    origin: 0,
    steps: 5,
    discrete: true,
    step: 1/4,
    labels: ["4", "8", "12", "20", "oopmh!"]
}, {
    name: "attack",
    title: "attack",
    type: "knob",
    min: 1,
    max: 11,
    default: 6,
    from: 30,
    to: 330,
    origin: 0,
    steps: 11,
    discrete: false,
    step: 0,
    labels: [":turtle:", "2", "3", "4", "5", "6", "7", "8", "9", "10", ":rabbit"]
}, {
    name: "release",
    title: "release",
    type: "knob",
    min: 1,
    max: 11,
    default: 6,
    from: 30,
    to: 330,
    origin: 0,
    steps: 11,
    discrete: false,
    step: 0,
    labels: [":turtle:", "2", "3", "4", "5", "6", "7", "8", "9", "10", ":rabbit"]
}, {
    name: "output",
    title: "output",
    type: "knob",
    min: 0,
    max: 1,
    default: 0.5,
    from: 30,
    to: 330,
    origin: 0,
    steps: 11,
    discrete: false,
    step: 0,
    labels: ["∞dB", "50", "40", "35", "30", "25", "20", "15", "10", "5", "0dB"]
}, [{
    type: "button",
    name: "bypass",
    title: "just color",
}, {
    type: "button",
    name: "hpfSc",
    title: "hpf sc",
}]]

// Gravity
const taDev004002 = [{
    name: "threshold",
    title: "threshold",
    type: "knob",
    min: -15,
    max: 15,
    default: 0,
    from: 30,
    to: 330,
    origin: 0,
    steps: 11,
    step: 0,
    discrete: false,
    labels: ["-15 dB", "-12", "-9", "-6", "-3", "0", "+3", "+6", "+9", "+12", "+15 dB"]
}, {
    name: "ratio",
    title: "ratio",
    type: "knob",
    min: 0,
    max: 1,
    default: 0,
    from: 150,
    to: 210,
    origin: 0,
    steps: 3,
    discrete: true,
    step: 1/2,
    labels: ["2", "4", "10"]
}, {
    name: "attack",
    title: "attack",
    type: "knob",
    min: 1,
    max: 6,
    default: 3,
    from: 105,
    to: 255,
    origin: 0,
    steps: 6,
    discrete: true,
    step: 1,
    labels: [":turtle:", "2", "3", "4", "5", ":rabbit:"]
}, {
    name: "release",
    title: "release",
    type: "knob",
    min: 1,
    max: 5,
    default: 3,
    from: 120,
    to: 240,
    origin: 0,
    steps: 5,
    discrete: true,
    step: 1,
    labels: ["auto", ":turtle:", "2", "3", ":rabbit:"]
}, {
    name: "makeup",
    title: "make up",
    type: "knob",
    min: 0,
    max: 15,
    default: 0.5,
    from: 30,
    to: 330,
    origin: 0,
    steps: 11,
    discrete: false,
    step: 0,
    labels: ["0 dB", "1.5", "3", "4.5", "6", "7.5", "9", "10.5", "12", "13.5", "15 dB"]
}, [{
    type: "button",
    name: "bypass",
    title: "bypass",
}, {
    type: "button",
    name: "hpfSc",
    title: "hpf sc",
}]]

// Icicle
const taDev005002 = [[{
    type: "label",
    title: "low filter"
}, {
    name: "low-bandwidth",
    title: "q",
    type: "knob",
    min: 0,
    max: 10,
    default: 5,
    from: 30,
    to: 330,
    origin: 0,
    steps: 11,
    step: 0,
    discrete: false,
    labels: [":q-wide:", "1", "2", "3", "4", "5", "6", "7", "8", "9", ":q-narrow:"]
}, {
    type: "button",
    name: "low-filter",
    title: ":filter-low:",
}], [{
    name: "low-gain",
    title: "gain",
    type: "knob",
    min: -15,
    max: 15,
    default: 0,
    from: 30,
    to: 330,
    origin: 0,
    steps: 11,
    step: 0,
    discrete: false,
    labels: ["-15 dB", "-12", "-9", "-6", "-3", "0", "+3", "+6", "+9", "+12", "+15 dB"]
}, {
    name: "low-frequency",
    title: ":frequency:",
    type: "knob",
    logarithmic: true,
    min: 12,
    max: 800,
    default: 100,
    from: 30,
    to: 330,
    origin: 0,
    steps: 11,
    step: 0,
    discrete: false,
    labels: ["12 hz", "20", "28", "43", "66", "100", "150", "230", "350", "530", "800 Hz"]
}], [{
    type: "label",
    title: "low middle filter"
}, {
    name: "low-middle-bandwidth",
    title: "q",
    type: "knob",
    min: 0,
    max: 10,
    default: 5,
    from: 30,
    to: 330,
    origin: 0,
    steps: 11,
    step: 0,
    discrete: false,
    labels: [":q-wide:", "1", "2", "3", "4", "5", "6", "7", "8", "9", ":q-narrow:"]
}], [{
    name: "low-middle-gain",
    title: "gain",
    type: "knob",
    min: -15,
    max: 15,
    default: 0,
    from: 30,
    to: 330,
    origin: 0,
    steps: 11,
    step: 0,
    discrete: false,
    labels: ["-15 dB", "-12", "-9", "-6", "-3", "0", "+3", "+6", "+9", "+12", "+15 dB"]
}, {
    name: "low-middle-frequency",
    title: ":frequency:",
    type: "knob",
    logarithmic: true,
    min: 125,
    max: 8000,
    default: 1000,
    from: 30,
    to: 330,
    origin: 0,
    steps: 11,
    step: 0,
    discrete: false,
    labels: ["125 hz", "190", "290", "440", "660", "1k", "1k5", "2k3", "2k5", "5k3", "8k hz"]
}], [{
    type: "label",
    title: "high middle filter"
}, {
    name: "high-middle-bandwidth",
    title: "q",
    type: "knob",
    min: 0,
    max: 10,
    default: 5,
    from: 30,
    to: 330,
    origin: 0,
    steps: 11,
    step: 0,
    discrete: false,
    labels: [":q-wide:", "1", "2", "3", "4", "5", "6", "7", "8", "9", ":q-narrow:"]
}], [{
    name: "high-middle-gain",
    title: "gain",
    type: "knob",
    min: -15,
    max: 15,
    default: 0,
    from: 30,
    to: 330,
    origin: 0,
    steps: 11,
    step: 0,
    discrete: false,
    labels: ["-15 dB", "-12", "-9", "-6", "-3", "0", "+3", "+6", "+9", "+12", "+15 dB"]
}, {
    name: "high-middle-frequency",
    title: ":frequency:",
    type: "knob",
    logarithmic: true,
    min: 400,
    max: 25600,
    default: 3200,
    from: 30,
    to: 330,
    origin: 0,
    steps: 11,
    step: 0,
    discrete: false,
    labels: ["400 hz", "600", "920", "1k4", "2k1", "3k2", "4k8", "7k3", "11k1", "17k", "25k6 hz"]
}], [{
    type: "label",
    title: "high filter",
}, {
    name: "high-bandwidth",
    title: "q",
    type: "knob",
    min: 0,
    max: 10,
    default: 5,
    from: 30,
    to: 330,
    origin: 0,
    steps: 11,
    step: 0,
    discrete: false,
    labels: [":q-wide:", "1", "2", "3", "4", "5", "6", "7", "8", "9", ":q-narrow:"]
}, {
    type: "button",
    name: "high-filter",
    title: ":filter-high:",
}], [{
    name: "high-gain",
    title: "gain",
    type: "knob",
    min: -15,
    max: 15,
    default: 0,
    from: 30,
    to: 330,
    origin: 0,
    steps: 11,
    step: 0,
    discrete: false,
    labels: ["-15 dB", "-12", "-9", "-6", "-3", "0", "+3", "+6", "+9", "+12", "+15 dB"]
}, {
    name: "high-frequency",
    title: ":frequency:",
    type: "knob",
    logarithmic: true,
    min: 570,
    max: 29400,
    default: 4100,
    from: 30,
    to: 330,
    origin: 0,
    steps: 11,
    step: 0,
    discrete: false,
    labels: ["570 hz", "850", "1k2", "1k8", "2k8", "4k1", "6k1", "9k", "13k4", "20k", "29k4 hz"]
}], [{
    type: "button",
    name: "bypass",
    title: "bypass",
}, {
    type: "button",
    name: "sound",
    title: "sound"
}]]

// Canyon
const taDev006002 = [...times(16, i => [{
    name: `channel-${i+1}`,
    type: "channel",
    title: `channel ${i+1}`,
}, {
    name: `level-${i+1}`,
    title: "level",
    type: "knob",
    min: -15,
    max: 15,
    default: 0,
    from: 30,
    to: 330,
    origin: 0,
    steps: 9,
    step: 0,
    discrete: false,
    labels: ["-15 db", "-11.25", "-7.5", "-3.75", "0", "+3.75", "+7.5", "+11.25", "+15 db"]
}, {
    name: `pan-${i+1}`,
    title: "pan",
    type: "knob",
    min: -1,
    max: 1,
    default: 0,
    from: 30,
    to: 330,
    origin: 0,
    steps: 9,
    step: 0,
    discrete: false,
    labels: ["LEFT", "75", "50", "25", "|", "25", "50", "75", "RIGHT"],
}]), [{
    name: "master",
    title: "master",
    type: "knob",
    min: 0,
    max: 1,
    default: 0.5,
    from: 30,
    to: 330,
    origin: 0,
    steps: 11,
    discrete: false,
    step: 0,
    labels: ["∞", "50", "40", "35", "30", "25", "20", "15", "10", "5", "0"]
}, {
    name: "blend",
    title: "insert blend",
    type: "knob",
    min: 0,
    max: 100,
    default: 50,
    from: 30,
    to: 330,
    origin: 0,
    steps: 11,
    step: 0,
    discrete: false,
    labels: ["dry", "10 %", "20 %", "30 %", "40 %", "50 %", "60 %", "70 %", "80%", "90 %", "wet"]
}]]

export function getControlsForPN(pn) {
    switch (pn) {
        case "TA-DEV-001-001": // TODO
        case "TA-DEV-001-002":
            return taDev001002

        case "TA-DEV-002-001": // TODO
        case "TA-DEV-002-002":
            return taDev002002

        case "TA-DEV-003-001": // TODO
        case "TA-DEV-003-002":
        case "TA-DEV-003-003":
            return taDev003002

        case "TA-DEV-004-001": // TODO
        case "TA-DEV-004-002":
        case "TA-DEV-004-003":
            return taDev004002

        case "TA-DEV-005-001": // TODO
        case "TA-DEV-005-002":
            return taDev005002

        case "TA-DEV-006-001": // TODO
        case "TA-DEV-006-002":
            return taDev006002

        default:
            return null
    }
}
