<template>
    <div
        class="flex flex-col items-center p-1 cursor-pointer bg-gray-100"
        @click="showGraph"
    >
        <span class="text-sm mb-1">{{ name }}</span>
        <span class="mb-1">{{ last }} {{ unit }}</span>
        <thumb-line-chart :data="data" />
    </div>
</template>

<script>
import ThumbLineChart from "~/components/ThumbLineChart"
import GraphModal from "~/components/GraphModal"

export default {
    inject: ["pushModal"],

    props: {
        name: {
            type: String,
            required: true,
        },
        unit: {
            type: String,
            default: () => {},
        },
        data: {
            type: Array,
            required: true
        }
    },

    computed: {
        last() {
            if (this.data[0]) {
                return this.data[0].y
            } else {
                return "-"
            }
        }
    },

    methods: {
        showGraph() {
            const pop = this.pushModal(GraphModal, {
                props: {
                    name: this.name,
                    data: this.data,
                    yUnit: this.unit,
                },
                on: {
                    hide: () => {
                        pop()
                    }
                }
            })
        }
    },

    components: {
        ThumbLineChart
    }
}
</script>
