<template>
    <div>
        <h2 class="mb-4">
            Sensors
        </h2>

        <div class="flex flex-col">
            <div class="flex">
                <chart-thumb
                    class="flex-1"
                    name="Temperature"
                    unit="ºC"
                    :data="tempData"
                />

                <chart-thumb
                    class="ml-3 flex-1"
                    name="CPU Temperature"
                    unit="ºC"
                    :data="cpuTempData"
                />
            </div>
            <div class="mt-3 flex">
                <chart-thumb
                    class="flex-1"
                    name="Humidity"
                    unit="%"
                    :data="humidityData"
                />
                <chart-thumb
                    class="ml-3 flex-1"
                    name="Pressure"
                    unit="Pa"
                    :data="pressureData"
                />
            </div>
        </div>
    </div>
</template>

<script>
import ChartThumb from "~/components/ChartThumb"

export default {
    props: {
        payloads: {
            type: Array,
            required: true,
        }
    },

    computed: {
        tempData() {
            return this.payloads.map(p => ({
                t: new Date(p.date),
                y: p.temperature,
            }))
        },

        cpuTempData() {
            return this.payloads.map(p => ({
                t: new Date(p.date),
                y: p.cpuTemperature,
            }))
        },

        humidityData() {
            return this.payloads.map(p => ({
                t: new Date(p.date),
                y: p.humidity,
            }))
        },

        pressureData() {
            return this.payloads.map(p => ({
                t: new Date(p.date),
                y: p.pressure,
            }))
        }
    },

    components: {
        ChartThumb,
    }
}
</script>
