<template>
    <button
        class="w-32 h-16 flex items-center hover:bg-gray-200 rounded overflow-hidden pl-6"
        @click="click"
    >
        <svg
            viewBox="0 0 1 1"
            class="w-4 h-4 fill-current text-gray-900 mr-4"
            :class="buttonClass"
            style="transition: color 0.25s"
        >
            <circle
                cx="0.5"
                cy="0.5"
                r="0.5"
                :fill="!value && 'none'"
                stroke-width="0.05"
            />
        </svg>
        <FancyLabel
            class="font-fancy text-gray-800 font-semibold"
            :label="title"
        />
    </button>
</template>

<script>
import FancyLabel from "~/components/FancyLabel"

export default {
    props: {
        value: Boolean,
        title: {
            type: String,
            required: true
        }
    },

    computed: {
        buttonClass() {
            return {
                "fill-current": this.value,
                "stroke-current": !this.value,
            }
        }
    },

    methods: {
        click() {
            this.$emit("input", !this.value)
        }
    },

    components: {
        FancyLabel
    }
}
</script>

