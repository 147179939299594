<template>
    <div class="rounded shadow bg-white relative p-5 overflow-hidden flex flex-col max-w-6xl m-4">
        <ta-button
            class="absolute top-0 right-0"
            theme="negative"
            @click="$emit('hide')"
        >
            <v-icon name="times" />
        </ta-button>

        <h1 class="mb-2">
            DATA PROTECTION POLICY
        </h1>

        <div class="overflow-y-auto">
            <p>
                TIERRA Audio S.L. (hereinafter THE COMPANY) is especially focused on protecting any data the USERS of our services leave through accessing our website. By means of this Privacy Policy (hereinafter, the Policy), THE COMPANY would like to inform the USERS of its website (https://my.tierra.audio.com) of how their personal data is used and processed, so that they can freely and voluntarily decide whether they wish to provide the information requested.
            </p>

            <p>
                THE COMPANY reserves the right to modify this Policy in order to adapt it to new legislation, jurisprudential criteria, best industrial practices, or its interests. Any modification to it will be communicated in good time, so that Users are fully aware of its content.
            </p>

            <p>
                Certain services provided on the website may contain particular conditions with provisions regarding the protection of Personal Data. You can find information regarding them in each corresponding section.
            </p>

            <h2>OWNERSHIP OF THE PROCESSING OF DATA</h2>
            <p>
                In some cases, the data collected by THE COMPANY will be incorporated into files owned by TIERRA Audio S.L., duly registered in the General Registry of Data Protection.
            </p>

            <h2>USE AND PURPOSE</h2>

            <p>
                The purpose of collecting and processing personal data is to manage, provide and customise the website's services and contents; each section will notify of this.
            </p>

            <h2>COMMUNICATION OF DATA</h2>

            <p>
                The data collected through the website will only be transferred to another entity after expressly informing the USER of such a transfer, such as when payment is made through financing, as THE COMPANY must transfer personal documentation provided by THE USER to that financial entity. Such documentation will not be stored by THE COMPANY in any case, regardless of whether the financing has been accepted or not.
            </p>

            <h2>UPDATING OF DATA</h2>

            <p>
                In order for the data in our databases to always correspond to our Users’ actual situation they must be kept updated, either by the User themselves directly when possible, or through communicating any changes to the corresponding department for that department to implement the change.
            </p>

            <h2>USE OF COOKIES</h2>

            <p>
                In order to improve the User experience and the services offered, THE COMPANY uses its own and third party cookies on the website. The use of cookies will never jeopardise the privacy of Users or their systems. By accessing our Web page the User accepts the COMPANY’s use of cookies.
            </p>

            <h2>DATA SECURITY</h2>

            <p>
                THE COMPANY's information system has implemented the legally required technical and organisational measures to guarantee the security and confidentiality of the data it stores, and prevent, as far as possible, its unauthorised alteration, loss, misuse or access.
            </p>

            <h2>USER RIGHTS</h2>

            <p>
                In all case the User can access their data, rectify it, cancel it and, if applicable, oppose its processing by either a) requesting it through written notification together with a photocopy of their ID, sent to the following postal address: TIERRA Audio, S.L. Calle Aguila Real, 29, Batres, 28976, Madrid, España, or by b) sending an e-mail to the following e-mail address: team@tierra.audio.
            </p>
        </div>
    </div>
</template>

<script>
import TaButton from "~/components/TaButton"

export default {
    components: {
        TaButton,
    }
}
</script>

<style scoped>
p {
    @apply mb-3;
}
</style>
