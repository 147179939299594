<template>
    <svg
        viewBox="0 0 1 1"
        :class="value ? 'text-gray-700' : 'text-gray-400'"
    >
        <circle
            cx="0.5"
            cy="0.5"
            r="0.5"
        />
    </svg>
</template>

<script>
export default {
    props: {
        value: Boolean
    }
}
</script>
