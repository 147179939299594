<template>
    <div>
        <div class="flex mb-2">
            <check-box
                v-model="alertCpuTemperature"
                class="mr-2"
            /> CPU Temperature alert
        </div>
        <div class="flex mb-2">
            <check-box
                v-model="alertTemperature"
                class="mr-2"
            /> Temperature alert
        </div>
        <div class="flex mb-2">
            <check-box
                v-model="alertPsu"
                class="mr-2"
            /> PSU alert
        </div>
        <div class="flex">
            <check-box
                v-model="weekly"
                class="mr-2"
            /> Weekly digest
        </div>

        <ta-button
            class="mt-4"
            @click="updateSubscriptions"
        >
            Update
        </ta-button>
    </div>
</template>

<script>
import axios from "axios"
import { mapState } from "vuex"

import CheckBox from "~/components/CheckBox"
import TaButton from "~/components/TaButton"

export default {
    data() {
        return {
            alertCpuTemperature: false,
            alertTemperature: false,
            alertPsu: false,
            weekly: false,
        }
    },

    computed: {
        ...mapState(["user"]),
    },

    created() {
        this.alertCpuTemperature = this.user.subscriptions.includes("alert:high-cpu-temperature")
        this.alertTemperature    = this.user.subscriptions.includes("alert:high-device-temperature")
        this.alertPsu            = this.user.subscriptions.includes("alert:psu-error")
        this.weekly              = this.user.subscriptions.includes("weekly")
    },

    methods: {
        async updateSubscriptions() {
            await axios.post("/update-profile", {
                subscriptions: [
                    this.alertCpuTemperature  && "alert:high-cpu-temperature",
                    this.alertTemperature     && "alert:high-device-temperature",
                    this.alertPsu             && "alert:psu-error",
                    this.weekly               && "weekly",
                ]
            })
            this.$store.dispatch("pushNotification", {
                type: "success",
                text: "Your preferences have been updated",
            })
        }
    },

    components: {
        CheckBox,
        TaButton,
    }
}
</script>
