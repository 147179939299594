<template>
    <div class="flex items-center h-16 justify-center">
        <ColorInput
            :value="value"
            @input="v => $emit('input', v)"
        />
        <FancyLabel
            class="ml-1 font-fancy text-gray-800 font-semibold"
            :label="control.title"
        />
    </div>
</template>

<script>
import FancyLabel from "~/components/FancyLabel"
import ColorInput from "~/components/ColorInput"

export default {
    props: {
        control: {
            type: Object,
            required: true,
        },
        value: {
            type: String,
            required: true,
        }
    },

    components: {
        FancyLabel,
        ColorInput,
    }
}
</script>
