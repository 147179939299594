<template>
    <div id="app">
        <router-view />

        <transition
            name="fade"
            appear
        >
            <div
                v-for="(modal, idx) in modalStack"
                :key="idx"
                v-lock-scroll
                class="fixed-background z-40"
                :class="modal.backgroundClass"
            >
                <component
                    :is="modal.component"
                    v-bind="modal.props"
                    v-on="modal.on"
                />
            </div>
        </transition>

        <div
            v-if="notifications.length"
            class="fixed right-0 bottom-0 p-3 max-w-sm w-full z-50"
        >
            <notification
                v-for="notification in lastNotifications"
                :key="notification.timestamp"
                :type="notification.type"
                :text="notification.text"
                @dismiss="dismissNotification(notification)"
            />
        </div>
    </div>
</template>

<script>
import mapValues from "lodash/mapValues"
import { mapState } from "vuex"

import DialogBox from "~/components/DialogBox"

import Notification from "~/components/Notification"

export default {
    data() {
        return {
            modalStack: [],
        }
    },

    provide() {
        const app = this
        return {
            pushModal(c, options) {
                return app.pushModal(c, options, this)
            },
            pushDialog: (text, options) => this.pushDialog(text, options),
        }
    },

    computed: {
        ...mapState(["notifications"]),

        lastNotifications() {
            return this.notifications.slice(-5)
        },
    },

    methods: {
        dismissNotification(notification) {
            this.$store.dispatch("dismissNotification", notification)
        },

        pushModal(component, { props={}, on={}, backgroundClass="overflow-hidden flex flex-col items-center justify-center" }={}, caller) {
            const modalInfo = {
                component,
                props,
                on: mapValues(on, fn => fn.bind(caller)),
                backgroundClass,
            }

            this.modalStack.push(modalInfo)

            return () => {
                const idx = this.modalStack.indexOf(modalInfo)
                if (idx != -1) this.modalStack.splice(idx)
            }
        },

        pushDialog(text, options={}) {
            return new Promise(resolve => {
                const pop = this.pushModal(DialogBox, {
                    props: {
                        text,
                        mode: options.mode || "alert",
                        acceptText: options.acceptText || "Accept",
                        cancelText: options.cancelText || "Cancel",
                    },
                    on: {
                        done: (res) => {
                            pop()
                            resolve(res)
                        }
                    },
                })
            })
        },
    },

    components: {
        Notification
    }
}
</script>

<style>
@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    @apply leading-normal font-sans text-gray-900 font-light;
}

html, body, #app {
    @apply h-full;
}

a {
    @apply outline-none;
}

a:focus {
    @apply font-normal;
}

button:focus {
    @apply outline-none;
}

hr {
    @apply border-t border-gray-400;
}

h1 {
    @apply font-hairline text-3xl;
}

h2 {
    @apply font-hairline text-2xl;
}

strong {
    @apply font-semibold;
}

.label {
    @apply pb-1 text-sm font-medium;
}

.fa-icon {
    height: 1em;
    width: auto;

    position: relative;
    bottom: 0.1rem;

    /* TODO */
    /* max-width: 100%; */
    max-height: 100%;
}
</style>
