<template>
    <form @submit.prevent="submit">
        <div class="mb-4">
            <div class="flex">
                <label class="label">
                    Serial number
                </label>
                <span
                    ref="snHelp"
                    tabindex="0"
                    class="text-sm cursor-pointer outline-none ml-auto"
                    @click="showSnHelp = !showSnHelp"
                >
                    <v-icon
                        scale="1.15"
                        name="info-circle"
                    />
                </span>
            </div>
            <input-text
                v-model="sn"
                placeholder="Serial number"
            />
            <input-hint
                v-if="$v.sn.$error"
                :hints="snHints"
            />
        </div>

        <div class="mb-4">
            <div class="flex">
                <label class="label">
                    PIN code
                </label>
                <span
                    ref="pinHelp"
                    tabindex="0"
                    class="text-sm cursor-pointer outline-none ml-auto"
                    @click="showPinHelp = !showPinHelp"
                >
                    <v-icon
                        scale="1.15"
                        name="info-circle"
                    />
                </span>
            </div>
            <input-text
                v-model="pin"
                placeholder="PIN code"
            />
            <input-hint
                v-if="$v.pin.$error"
                :hints="pinHints"
            />
        </div>

        <ta-button class="w-full">
            Add
        </ta-button>
        <pop-up
            v-if="showSnHelp"
            class="bg-white rounded p-3 text-sm shadow-lg"
            :reference="$refs.snHelp"
            @hide="showSnHelp=false"
        >
            You can find the serial number in the back of your device.
        </pop-up>

        <pop-up
            v-if="showPinHelp"
            class="bg-white rounded text-sm p-3 shadow-lg"
            :reference="$refs.pinHelp"
            @hide="showPinHelp=false"
        >
            Get the PIN code in "Settings / Pulse / PIN code".
        </pop-up>
    </form>
</template>

<script>
import { required } from "vuelidate/lib/validators"

import InputText from "~/components/InputText"
import TaButton from "~/components/TaButton"
import InputHint from "~/components/InputHint"
import PopUp from "~/components/PopUp"

export default {
    props: {
        initialSn: {
            type: String,
            default: "",
        },
    },

    data() {
        return {
            sn: this.initialSn,
            pin: "",

            showSnHelp: false,
            showPinHelp: false,
        }
    },

    computed: {
        snHints() {
            return [
                !this.$v.sn.required && "SN is required",
            ]
        },

        pinHints() {
            return [
                !this.$v.sn.required && "PIN is required",
            ]
        }
    },

    validations: {
        sn: {
            required,
        },
        pin: {
            required,
        },
    },

    methods: {
        async submit() {
            this.$v.$touch()
            if (this.$v.$invalid) return // TODO flash

            this.$emit("add", {
                sn: this.sn,
                pin: this.pin,
            })
        }
    },

    components: {
        InputText,
        TaButton,
        InputHint,
        PopUp,
    }
}
</script>
