<template>
    <div
        style="height: 200px"
    />
</template>

<script>
import "leaflet/dist/leaflet.css"
import leafGreen from "~/images/leaf-green.png"
import leafShadow from "~/images/leaf-shadow.png"

export default {
    props: {
        latitude: {
            type: Number,
            required: true
        },
        longitude: {
            type: Number,
            required: true
        },
        name: {
            type: String,
            required: true,
        }
    },

    mounted() {
        import("leaflet").then(L => {
            this.map = L.map(this.$el).setView([this.latitude, this.longitude], 13)

            const greenIcon = L.icon({
                iconUrl: leafGreen,
                shadowUrl: leafShadow,

                iconSize:     [38, 95],
                shadowSize:   [50, 64],
                iconAnchor:   [22, 94],
                shadowAnchor: [4, 62],
                popupAnchor:  [-3, -76]
            })

            L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            }).addTo(this.map)

            L.marker([this.latitude, this.longitude], { icon: greenIcon }).addTo(this.map)
        })
    },

    beforeDestroy() {
        this.map.destroy()
    }
}
</script>
