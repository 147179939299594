<template>
    <div>
        <h1>issueFiled</h1>

        <iframe
            class="max-w-4xl mx-auto w-full"
            height="300"
            :src="issueFiled"
        />

        <hr>

        <h1>situation</h1>

        <iframe
            class="max-w-4xl mx-auto w-full"
            height="300"
            :src="situation"
        />

        <hr>

        <h1>forgot</h1>

        <iframe
            class="max-w-4xl mx-auto w-full"
            height="300"
            :src="forgot"
        />

        <hr>

        <h1>validate</h1>

        <iframe
            class="max-w-4xl mx-auto w-full"
            height="300"
            :src="validate"
        />

        <hr>

        <h1>weekly</h1>

        <iframe
            class="max-w-4xl mx-auto w-full"
            height="300"
            :src="weekly"
        />
    </div>
</template>

<script>
import axios from "axios"
import urlJoin from "url-join"

export default {
    data() {
        return {

        }
    },

    computed: {
        issueFiled() {
            return urlJoin(axios.defaults.baseURL, "/dev/issueFiled")
        },

        situation() {
            return urlJoin(axios.defaults.baseURL, "/dev/situation")
        },

        forgot() {
            return urlJoin(axios.defaults.baseURL, "/dev/forgot")
        },

        validate() {
            return urlJoin(axios.defaults.baseURL, "/dev/validate")
        },

        weekly() {
            return urlJoin(axios.defaults.baseURL, "/dev/weekly")
        }
    },
}
</script>
