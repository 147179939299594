<template>
    <div class="relative shadow rounded bg-white p-6">
        <ta-button
            class="absolute top-0 right-0"
            theme="negative"
            @click="$emit('hide')"
        >
            <v-icon name="times" />
        </ta-button>

        <h1 class="mb-6 text-center">
            Recent activity
        </h1>

        <ul>
            <li
                v-for="event in cookedEvents"
                :key="event.id"
            >
                {{ event.when | wordsago }} ago | {{ event.text }}
            </li>
        </ul>
    </div>
</template>

<script>
import { cookDeviceEvent } from "~/lib/utils"

import TaButton from "~/components/TaButton"

export default {
    props: {
        events: {
            type: Array,
            required: true,
        },
        lastPulseDate: {
            type: Date,
            default: () => null,
        }
    },

    computed: {
        cookedEvents() {
            const events = this.events.map(cookDeviceEvent)

            if (this.lastPulseDate) {
                events.unshift({
                    id: "last-pulse",
                    when: this.lastPulseDate,
                    text: "Device last pulse"
                })
            }

            return events
        }
    },

    components: {
        TaButton
    }
}
</script>
