<template>
    <div>
        <div class="bg-black px-16 pt-8 pb-6 relative mb-12">
            <router-link to="/">
                <img
                    class="mx-auto max-w-xs w-full"
                    src="../images/logo_light_big.png"
                >
            </router-link>
            <svg
                viewBox="0 0 1 1"
                class="fill-current text-black absolute inset-x-0 h-12 w-full"
                style="top: 100%"
                preserveAspectRatio="none"
            >
                <path d="M0 0 L1 0 L0 1 Z" />
            </svg>
        </div>

        <genuine-body
            :device="device"
            :sn="sn"
        />

        <div class="fixed bottom-0 inset-x-0 flex items-end flex-wrap justify-between">
            <social-bar class="mr-auto" />
            <love-bar class="ml-auto" />
        </div>
    </div>
</template>

<script>
import LoveBar from "~/components/LoveBar"
import SocialBar from "~/components/SocialBar"

import GenuineBody from "./GenuineBody"

export default {
    props: {
        sn: {
            type: String,
            required: true,
        },
        device: {
            type: Object,
            default: null,
        },
    },

    components: {
        SocialBar,
        LoveBar,
        GenuineBody,
    }
}
</script>
