<template>
    <default-layout class="p-3">
        <div class="flex border-b mb-3">
            <tab-button
                :active="tab == 'devices'"
                @click="tab = 'devices'"
            >
                Devices
            </tab-button>
            <tab-button
                class="ml-3"
                :active="tab == 'updates'"
                @click="tab = 'updates'"
            >
                Updates
            </tab-button>
            <tab-button
                class="ml-3"
                :active="tab == 'emails'"
                @click="tab = 'emails'"
            >
                Emails
            </tab-button>
        </div>

        <devices-tab v-if="tab == 'devices'" />
        <updates-tab v-else-if="tab == 'updates'" />
        <emails-tab v-else-if="tab == 'emails'" />
    </default-layout>
</template>

<script>
import TabButton from "~/components/TabButton"
import DefaultLayout from "~/layouts/DefaultLayout"

import DevicesTab from "../components/DevicesTab"
import UpdatesTab from "../components/UpdatesTab"
import EmailsTab from "../components/EmailsTab"

export default {
    data() {
        return {
            tab: "devices",
        }
    },

    components: {
        DefaultLayout,
        TabButton,

        DevicesTab,
        UpdatesTab,
        EmailsTab,
    }
}
</script>

<style scoped>
th {
    @apply p-1;
}

td {
    @apply p-1 border-r;
}

td:first-child {
    @apply border-l;
}

tr {
    @apply border-b;
}

tr:hover {
    @apply bg-gray-300;
}
</style>
