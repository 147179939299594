<template>
    <div
        class="px-4 py-3 bg-white mt-3 shadow-md rounded cursor-pointer flex items-center"
        @click="click"
    >
        <v-icon
            :name="iconName"
            class="mr-2"
            :class="iconClass"
            scale="1.5"
        />
        {{ text }}
    </div>
</template>

<script>
export default {
    props: {
        type: {
            type: String,
            required: true,
            validator: type => ["success", "info", "warning", "error"].indexOf(type) != -1
        },
        text: {
            type: String,
            default: () => "",
        }
    },

    computed: {
        iconName() {
            switch (this.type) {
                case "success": return "regular/check-circle"
                case "info":    return "info-circle"
                case "warning": return "exclamation-triangle"
                case "error":   return "regular/times-circle"

                default:
                    throw new Error()
            }
        },

        iconClass() {
            switch (this.type) {
                case "success": return "text-green-400"
                case "info":    return "text-blue-400"
                case "warning": return "text-orange-400"
                case "error":   return "text-red-400"

                default:
                    throw new Error()
            }
        }
    },

    methods: {
        click() {
            this.$emit("dismiss")
        }
    }
}
</script>
