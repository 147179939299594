<template>
    <div
        tabindex="0"
        class="tab px-4 py-3 cursor-pointer outline-none"
        :class="{ active }"
        @click="$emit('click')"
    >
        <slot />
    </div>
</template>

<script>
export default {
    props: {
        active: Boolean,
    }
}
</script>

<style scoped>
.tab {
    transition: border 0.25s, background-color 0.25s;
}

.tab:hover, .tab:focus {
    @apply bg-gray-100;
}

.active {
    @apply border-gray-900 border-b-2
}

</style>
