import Vue from "vue"
import VueRouter from "vue-router"

Vue.use(VueRouter)

import LoginView from "~/views/LoginView"
import RememberView from "~/views/RememberView"
import RackView from "~/views/RackView"
import ProfileView from "~/views/ProfileView"
import RegisterView from "~/views/RegisterView"
import DeviceView from "~/views/DeviceView"
import ResetPasswordView from "~/views/ResetPasswordView"
import SupportView from "~/views/SupportView"
import GenuineView from "~/views/GenuineView"
import TrackView from "~/views/TrackView"
import TracksView from "~/views/TracksView"

import AdminView from "~/modules/admin/views/AdminView"

import NotFoundView from "~/views/NotFoundView"

const routes = [
    {
        name: "home",
        path: "/",
        redirect: { name: "rack" },
    },

    {
        name: "track",
        path: "/tracks/:trackId/:trackSlug?",
        component: TrackView,
        props: true,
    },

    {
        name: "tracks",
        path: "/tracks",
        component: TracksView,
    },

    {
        name: "login",
        path: "/login",
        component: LoginView,
        props: route => ({ go: route.query.go }),
        meta: { public: true },
    },

    {
        name: "register",
        path: "/register",
        component: RegisterView,
        props: route => ({ with: route.query.with }),
        meta: { public: true },
    },

    {
        name: "reset",
        path: "/reset",
        component: ResetPasswordView,
        props: route => ({ token: route.query.token }),
        meta: { public: true },
    },

    {
        name: "remember",
        path: "/remember",
        component: RememberView,
        meta: { public: true },
    },

    {
        name: "genuine",
        path: "/genuine/:sn",
        component: GenuineView,
        props: true,
        meta: { public: true },
    },

    {
        name: "rack",
        path: "/rack",
        props: route => ({ sn: route.query.add }),
        component: RackView,
    },

    {
        name: "profile",
        path: "/profile",
        component: ProfileView,
    },

    {
        name: "device",
        path: "/device/:id",
        props: route => ({ id: Number(route.params.id) }),
        component: DeviceView,
    },

    {
        name: "admin",
        path: "/admin",
        component: AdminView,
        meta: { admin: true },
    },

    {
        name: "support",
        path: "/support/:sn?",
        props: true,
        component: SupportView,
    },

    {
        name: "not-found",
        path: "*",
        component: NotFoundView,
        meta: { public: true },
    }
]

export function createRouter() {
    return new VueRouter({
        mode: "history",
        routes
    })
}
