<template>
    <div class="bg-gray-900 p-2 flex flex-wrap md:flex-no-wrap text-gray-100">
        <div class="mx-2 lg:mx-6 my-6">
            <h2 class="mb-3 uppercase">
                Contact
            </h2>
            <p class="mb-3">
                Talk to a specialist by <a
                    class="font-normal"
                    href="mailto:team@tierra.audio"
                >
                    e-mail
                </a> or phone:
            </p>
            <ul>
                <li>
                    Free from Spain  900 377 043
                </li>
                <li>
                    Madrid +34 911 976 532
                </li>
                <li>
                    London +44 20 3318 9947
                </li>
                <li>
                    Los Angeles +1 (760) 284-6009
                </li>
            </ul>
        </div>
        <div class="flex flex-col items-start mx-2 md:mx-6 my-6">
            <h2 class="mb-3 uppercase">
                Legal
            </h2>
            <button
                class="font-normal mb-3"
                type="button"
                @click="showPrivacyPolicty"
            >
                Privacy policy
            </button>
            <p class="mt-auto self-center">
                <copyright-notice />
            </p>
        </div>
        <div class="md:ml-auto self-center px-2">
            <img
                class="w-56"
                src="../images/logo_light_big.png"
            >
        </div>
    </div>
</template>

<script>
import PrivacyPolicyModal from "~/components/PrivacyPolicyModal"
import CopyrightNotice from "~/components/CopyrightNotice"

export default {
    inject: ["pushModal"],

    methods: {
        showPrivacyPolicty() {
            const pop = this.pushModal(PrivacyPolicyModal, {
                on: {
                    hide: () => {
                        pop()
                    }
                }
            })
        }
    },

    components: {
        CopyrightNotice
    }
}
</script>
