<template>
    <default-layout>
        <div class="p-6 min-h-screen">
            <h1 class="mb-4 text-center">
                Account settings
            </h1>

            <template v-if="mounted">
                <div
                    v-show="$mq.lg"
                    key="lg"
                    class="flex"
                >
                    <div class="pr-4 w-1/2">
                        <div class="p-4 border rounded shadow">
                            <h1 class="mb-2">
                                Profile
                            </h1>
                            <profile-settings-tab />
                        </div>
                    </div>
                    <div class="pl-4 w-1/2">
                        <div class="p-4 border rounded shadow">
                            <h1 class="mb-2">
                                Subscriptions
                            </h1>
                            <profile-subscriptions-tab />
                        </div>
                    </div>
                </div>

                <div v-show="!$mq.lg">
                    <div class="flex border-b mb-4">
                        <tab-button
                            :active="tab == 'profile'"
                            @click="tab = 'profile'"
                        >
                            Profile
                        </tab-button>
                        <tab-button
                            class="ml-3"
                            :active="tab == 'subscriptions'"
                            @click="tab = 'subscriptions'"
                        >
                            Subscriptions
                        </tab-button>
                    </div>

                    <profile-settings-tab v-if="tab == 'profile'" />
                    <profile-subscriptions-tab v-else-if="tab == 'subscriptions'" />
                </div>
            </template>
        </div>

        <main-footer class="mt-10" />
    </default-layout>
</template>

<script>
import DefaultLayout from "~/layouts/DefaultLayout"
import TabButton from "~/components/TabButton"

import ProfileSettingsTab from "~/components/ProfileSettingsTab"
import ProfileSubscriptionsTab from "~/components/ProfileSubscriptionsTab"
import MainFooter from "~/components/MainFooter"

export default {
    data() {
        return {
            tab: "profile",
            mounted: false,
        }
    },

    mounted() {
        this.mounted = true
    },

    components: {
        TabButton,
        DefaultLayout,

        ProfileSettingsTab,
        ProfileSubscriptionsTab,
        MainFooter,
    }
}
</script>
