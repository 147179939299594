<template>
    <div
        class="p-2 rounded-full overflow-hidden"
        :class="buttonClass"
        style="transition: background-color 0.25s"
    >
        <div
            class="w-4 h-4 rounded-full overflow-hidden border"
            :class="swatchClass"
            style="transition: background-color 0.25s, border-color 0.25s"
        />
    </div>
</template>

<script>
export default {
    props: {
        color: {
            type: String,
            required: true,
        }
    },

    computed: {
        buttonClass() {
            if (this.color == "black") {
                return `hover:bg-gray-600`
            } else if (this.color == "white") {
                return `hover:bg-gray-200`
            } else {
                return [
                    `hover:bg-${this.color}-200`
                ]
            }
        },

        swatchClass() {
            if (this.color == "black") {
                return [
                    `bg-black`,
                    `border-black`,
                ]
            } else if (this.color == "white") {
                return [
                    `bg-white`,
                    `border-gray-500`,
                ]
            } else {
                return [
                    `bg-${this.color}-500`,
                    `border-${this.color}-600`,
                ]
            }
        },
    }
}
</script>
