<template>
    <textarea
        class="placeholder-gray-500"
        v-bind="{ value, rows, placeholder }"
        @input="input"
    />
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            default: "",
        },
        placeholder: {
            type: String,
            default: ""
        },
        rows: {
            type: Number,
            default: 10,
        }
    },

    methods: {
        input(event) {
            this.$emit("input", event.currentTarget.value)
        }
    }
}
</script>

<style scoped>
textarea {
    @apply py-3 px-4 outline-none border w-full;

    transition: border 0.25s, background-color 0.25s;
}

textarea:hover {
    @apply border-gray-600;
}

textarea:focus {
    @apply bg-gray-100 border-gray-600;
}
</style>
