<template>
    <default-layout>
        <div class="p-6 w-full max-w-3xl mx-auto min-h-screen">
            <h1 class="mb-4 text-center">
                Support
            </h1>

            <form @submit.prevent="submit">
                <div class="mb-2">
                    <label class="label">
                        Type
                    </label>
                    <input-select
                        v-model="type"
                        :options="options"
                    />
                </div>

                <div class="mb-2">
                    <label class="label">
                        Device
                    </label>
                    <div
                        v-if="!selectedDevice"
                        class="text-gray-700 px-1"
                    >
                        Do you want to reference a
                        <button
                            ref="deviceButton"
                            type="button"
                            @click="devicePopUpVisible = !devicePopUpVisible"
                        >
                            device
                        </button>?
                    </div>
                    <div
                        v-else
                        class="flex items-center px-4 py-1 cursor-pointer bg-gray-200 hover:bg-gray-300"
                        @click="deviceSn = null"
                    >
                        <div class="flex flex-col flex-1">
                            <span>
                                {{ selectedDevice.alias }}
                            </span>
                            <span class="text-xs">
                                {{ selectedDevice.sn }}
                            </span>
                        </div>
                        <div>
                            <v-icon
                                class="text-gray-700"
                                name="regular/times-circle"
                            />
                        </div>
                    </div>
                </div>

                <div class="mb-2">
                    <label class="label">
                        Description
                    </label>
                    <text-area
                        v-model="description"
                        placeholder="Please tell us how can we help you."
                    />
                    <input-hint
                        v-if="$v.description.$error"
                        :hints="descriptionHints"
                    />
                </div>

                <div class="flex">
                    <ta-button class="ml-auto">
                        Send
                    </ta-button>
                </div>
            </form>

            <pop-up
                v-if="devicePopUpVisible"
                :reference="$refs.deviceButton"
                class="bg-white rounded flex flex-col py-2 shadow-lg"
            >
                <div
                    v-for="device in devices"
                    :key="device.id"
                    class="flex flex-col px-4 py-1 cursor-pointer hover:bg-gray-200"
                    @click="selectDevice(device)"
                >
                    <span>
                        {{ device.alias }}
                    </span>
                    <span class="text-xs">
                        {{ device.sn }}
                    </span>
                </div>
            </pop-up>
        </div>

        <main-footer class="mt-10" />
    </default-layout>
</template>

<script>
import axios from "axios"
import { required } from "vuelidate/lib/validators"

import PopUp from "~/components/PopUp"
import TextArea from "~/components/TextArea"
import TaButton from "~/components/TaButton"
import InputSelect from "~/components/InputSelect"
import DefaultLayout from "~/layouts/DefaultLayout"
import InputHint from "~/components/InputHint"
import MainFooter from "~/components/MainFooter"

export default {
    props: {
        sn: {
            type: String,
            default: ""
        },
    },

    data() {
        return {
            description: "",
            type: "query",
            deviceSn: this.sn,

            devicePopUpVisible: false,

            options: [{
                name: "Query",
                value: "query",
            }, {
                name: "Issue",
                value: "issue",
            }]
        }
    },

    computed: {
        devices() {
            return Object.values(this.$store.state.devices)
        },

        selectedDevice() {
            return this.devices.find(d => d.sn == this.deviceSn)
        },

        descriptionHints() {
            return [
                !this.$v.description.required && "Description is required",
            ]
        }
    },

    validations: {
        description: {
            required
        }
    },

    async mounted() {
        await this.$store.dispatch("refreshDevices")
    },

    methods: {
        async submit() {
            this.$v.$touch()
            if (this.$v.$invalid) return // TODO flash

            try {
                await axios.post("/support", {
                    type: this.type,
                    deviceId: this.selectedDevice.id,
                    description: this.description,
                })
            } catch (error) {
                this.$store.dispatch("notifyOfError", error)
                return
            }
            this.$store.dispatch("pushNotification", {
                type: "success",
                text: "Your issue has been filed.",
            })
        },

        selectDevice(device) {
            this.deviceSn = device.sn
            this.devicePopUpVisible = false
        }
    },

    components: {
        DefaultLayout,
        TextArea,
        TaButton,
        InputSelect,
        InputHint,
        PopUp,
        MainFooter,
    }
}
</script>
