<template>
    <div
        class="relative flex items-center justify-center bg-gray-200 overflow-hidden rounded"
        @touchstart="touchstart"
        @mousedown="mousedown"
    >
        <div
            class="absolute top-0 h-full bg-gray-500 opacity-25 overflow-hidden rounded"
            :style="barStyle"
        />
        <div
            class="w-px bg-gray-600 opacity-50 absolute top-0 h-full"
            :style="originStyle"
        />
        <div class="pointer-events-none flex items-center">
            <slot />
        </div>
    </div>
</template>

<script>
import {
    clamp,
    lerp,
} from "~/lib/utils"

export default {
    props: {
        value: {
            type: Number,
            required: true,
        },
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: 1,
        },
        logarithmic: {
            type: Boolean,
            default: false,
        },
        origin: {
            type: Number,
            default: 0,
        },
    },

    computed: {
        normalizedValue() {
            return this.logarithmic ? Math.log(this.value) : this.value
        },

        normalizedMax() {
            return this.logarithmic ? Math.log(this.max) : this.max
        },

        normalizedMin() {
            return this.logarithmic ? Math.log(this.min) : this.min
        },

        normalizedOrigin() {
            return this.logarithmic ? this.origin > 0 ? Math.log(this.origin) : 0 : this.origin
        },


        barStyle() {
            const v = (this.normalizedValue - this.normalizedMin)
                / (this.normalizedMax - this.normalizedMin)
            const o = (this.normalizedOrigin - this.normalizedMin)
                / (this.normalizedMax - this.normalizedMin)

            const w = Math.abs(v - o)
            const l = v >= o ? o : o - w

            const style = {
                left: `${100 * l}%`,
                width: `${100 * w}%`
            }

            return style
        },

        originStyle() {
            let o = (this.normalizedOrigin - this.normalizedMin) / (this.normalizedMax - this.normalizedMin)
            if (o <= 0) o = -99
            return {
                left: `${100 * o}%`
            }
        }
    },

    methods: {
        mousedown(event) {
            event.preventDefault()

            const x0 = event.clientX

            const { left, width } = this.$el.getBoundingClientRect()

            let value = clamp(this.normalizedMin, this.normalizedMax,
                lerp(this.normalizedMin, this.normalizedMax, (x0 - left) / width))
            if (this.logarithmic) {
                value = Math.exp(value)
            }
            this.$emit("input", value)

            const mousemove = (event) => {
                this.$emit("dragging")
                const x1 = event.clientX

                let value = clamp(this.normalizedMin, this.normalizedMax, lerp(this.normalizedMin, this.normalizedMax, (x1 - left) / width))
                if (this.logarithmic) {
                    value = Math.exp(value)
                }
                this.$emit("input", value)
            }

            const mouseup = () => {
                this.$emit("dragend")
                document.removeEventListener("mousemove", mousemove)
                document.removeEventListener("mouseup", mouseup)
            }

            document.addEventListener("mousemove", mousemove)
            document.addEventListener("mouseup", mouseup)
        },

        touchstart(event) {
            if (event.touches.length != 1) return  // We may be gesturing
            const touch = Array.from(event.targetTouches)[0]
            if (!touch) return

            const x0 = touch.clientX
            const id = touch.identifier

            const { left, width } = this.$el.getBoundingClientRect()

            let value = clamp(this.normalizedMin, this.normalizedMax,
                lerp(this.normalizedMin, this.normalizedMax, (x0 - left) / width))
            if (this.logarithmic) {
                value = Math.exp(value)
            }
            this.$emit("input", value)

            const touchmove = (event) => {
                const touch = Array.from(event.touches).find(t => t.identifier == id)
                if (!touch) return

                this.$emit("dragging")
                const x1 = touch.clientX

                let value = clamp(this.normalizedMin, this.normalizedMax,
                    lerp(this.normalizedMin, this.normalizedMax,
                        (x1 - left) / width))
                if (this.logarithmic) {
                    value = Math.exp(value)
                }
                this.$emit("input", value)
            }

            const touchend = () => {
                this.$emit("dragend")
                document.removeEventListener("touchmove", touchmove)
                document.removeEventListener("touchend", touchend)
                document.removeEventListener("touchcancel", touchend)
            }

            document.addEventListener("touchmove", touchmove)
            document.addEventListener("touchend", touchend)
            document.addEventListener("touchcancel", touchend)
        }
    }
}
</script>
