<template>
    <div class="mx-auto max-w-sm px-3 py-16 min-h-screen flex flex-col justify-center">
        <div class="fixed top-0 inset-x-0 flex px-4 py-2">
            <a
                href="https://tierra.audio"
                target="_blank"
                rel="noopener noreferrer"
                class="text-sm text-gray-700"
            >
                <v-icon
                    name="chevron-left"
                    scale="0.7"
                    class="mr-px"
                />
                Home page
            </a>
        </div>

        <img
            class="mb-10"
            :src="logoUrl"
        >

        <form
            class="flex flex-col mt-12"
            @submit.prevent="submit"
        >
            <div class="mb-4">
                <input-text
                    v-model="email"
                    type="email"
                    placeholder="Email"
                />
                <input-hint
                    v-if="$v.email.$error"
                    :hints="emailHints"
                />
            </div>
            <div class="mb-2">
                <input-text
                    v-model="password"
                    type="password"
                    placeholder="Password"
                />
                <input-hint
                    v-if="$v.password.$error"
                    :hints="passwordHints"
                />
            </div>

            <router-link
                to="/remember"
                class="self-center mb-4 text-sm"
            >
                Forgot your password?
            </router-link>

            <div class="flex items-baseline">
                <div class="w-full text-center">
                    <router-link to="/register">
                        Register
                    </router-link>
                </div>
                <ta-button class="w-full">
                    Login
                </ta-button>
            </div>
        </form>

        <div class="fixed bottom-0 inset-x-0 justify-between flex flex-wrap items-center">
            <social-bar />
            <love-bar />
        </div>

        <copyright-notice class="fixed bottom-0 left-0 ml-4 mb-10 text-xs font-semibold text-gray-500" />
    </div>
</template>

<script>
import {
    required,
    email,
} from "vuelidate/lib/validators"

import InputText from "~/components/InputText"
import TaButton from "~/components/TaButton"
import InputHint from "~/components/InputHint"

import SocialBar from "~/components/SocialBar"
import LoveBar from "~/components/LoveBar"
import CopyrightNotice from "~/components/CopyrightNotice"

import logoUrl from "~/images/favic.gif"

export default {
    props: {
        go: {
            type: String,
            default: () => "",
        }
    },

    data() {
        return {
            email: "",
            password: "",

            logoUrl
        }
    },

    computed: {
        emailHints() {
            return [
                !this.$v.email.required && "Email is required",
                !this.$v.email.email && "Email is invalid",
            ]
        },
        passwordHints() {
            return [
                !this.$v.password.required && "Password is required",
            ]
        }
    },

    validations: {
        email: {
            required,
            email,
        },
        password: {
            required,
        }
    },

    methods: {
        async submit() {
            this.$v.$touch()
            if (this.$v.$invalid) return // TODO flash

            const ok = await this.$store.dispatch("login", {
                email: this.email,
                password: this.password,
            })
            if (!ok) return

            if (this.go) {
                this.$router.push(this.go)
            } else {
                this.$router.push({ name: "home" })
            }
        },
    },

	components: {
        InputText,
        TaButton,
        InputHint,
        SocialBar,
        LoveBar,
        CopyrightNotice,
	}
}
</script>
