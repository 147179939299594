<template>
    <div class="min-h-screen flex">
        <div class="bg-black relative pt-12 pl-8 flex flex-col">
            <router-link to="/">
                <img
                    class="mx-auto max-w-xs"
                    src="../images/logo_light_big.png"
                >
            </router-link>

            <p class="mt-8 text-center text-xl font-medium text-gray-400">
                It’s not marketing, it’s engineering.
            </p>

            <svg
                viewBox="0 0 1 1"
                class="fill-current text-black absolute inset-y-0 h-full w-12"
                style="left: 100%"
                preserveAspectRatio="none"
            >
                <path d="M0 0 L1 0 L0 1 Z" />
            </svg>
        </div>
        <div class="py-12 flex-1">
            <genuine-body
                class="mx-auto max-w-lg"
                :device="device"
                :sn="sn"
            />
        </div>

        <social-bar class="fixed bottom-0 left-0 text-gray-100" />
        <love-bar class="fixed bottom-0 right-0" />
    </div>
</template>

<script>
import LoveBar from "~/components/LoveBar"
import SocialBar from "~/components/SocialBar"

import GenuineBody from "./GenuineBody"

export default {
    props: {
        sn: {
            type: String,
            required: true,
        },
        device: {
            type: Object,
            default: null,
        },
    },

    components: {
        SocialBar,
        LoveBar,
        GenuineBody,
    }
}
</script>
