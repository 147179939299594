<template>
    <div>
        <h2 class="mb-4">
            This Device = 1 Planted Tree
        </h2>

        <div class="flex">
            <div class="pr-2">
                <span class="text-xs font-semibold">Picture</span>
                <img
                    class="max-w-xs"
                    :src="pictureUrl"
                >
            </div>

            <div class="pl-2">
                <span class="text-xs font-semibold">Description</span>
                <p>
                    {{ treeInfo.description }}
                </p>
            </div>
        </div>

        <div>
            <span class="text-xs font-semibold">Location</span>

            <div>
                {{ treeInfo.location }}
            </div>

            <address-map
                :latitude="Number(treeInfo.latitude)"
                :longitude="Number(treeInfo.longitude)"
                name=""
            />
        </div>
    </div>
</template>

<script>
import AddressMap from "~/components/AddressMap"
import urlJoin from "url-join"
import axios from "axios"

export default {
    props: {
        treeInfo: {
            type: Object,
            required: true,
        }
    },

    computed: {
        pictureUrl() {
            return urlJoin(axios.defaults.baseURL, "dev/tree-images", this.treeInfo.picture)
        }
    },

    components: {
        AddressMap,
    }
}
</script>
