<template>
    <form
        class="rounded shadow bg-white p-3 relative"
        @submit.prevent="create"
    >
        <ta-button
            type="button"
            class="absolute top-0 right-0"
            theme="negative"
            @click="$emit('close')"
        >
            <v-icon name="times" />
        </ta-button>

        <div class="p-4">
            <label class="label">
                Track name
            </label>
            <input-text v-model.trim="name" />
            <input-hint
                v-if="$v.name.$error"
                :hints="nameHints"
            />
        </div>

        <div class="flex justify-end">
            <ta-button>
                Add
            </ta-button>
        </div>
    </form>
</template>

<script>
import { required } from "vuelidate/lib/validators"

import InputText from "~/components/InputText"
import InputHint from "~/components/InputHint"
import TaButton from "~/components/TaButton"

export default {
    data() {
        return {
            name: ""
        }
    },

    computed: {
        nameHints() {
            return [
                !this.$v.name.required && "Track name is required",
            ]
        }
    },

    validations: {
        name: {
            required,
        }
    },

    methods: {
        create() {
            this.$v.$touch()
            if (this.$v.$invalid) return

            this.$emit("add", this.name)
        }
    },

    components: {
        InputText,
        InputHint,
        TaButton,
    }
}
</script>
