<template>
    <div class="dialog-box max-w-3xl shadow rounded bg-white p-6">
        <form @submit.prevent="submit">
            <div class="flex">
                <div class="flex-1 pr-3">
                    <label>SN</label>
                    <input-text v-model="form.sn" />
                    <label>Secure ID</label>
                    <input-text v-model="form.secureId" />
                    <label>MAC</label>
                    <input-text v-model="form.mac" />
                    <label>Alias</label>
                    <input-text v-model="form.alias" />
                </div>
                <div class="flex-1 pl-3">
                    <label>pin code</label>
                    <input-text v-model="form.pinCode" />
                    <label>model</label>
                    <input-text v-model="form.model" />
                    <label>locked at</label>
                    <input-text
                        v-model="form.lockedAt"
                        readonly
                    />
                    <label>user id</label>
                    <input-text v-model="form.userId" />
                    <label>linked at</label>
                    <input-text
                        v-model="form.linkedAt"
                    />
                </div>
            </div>

            <div class="flex justify-end mt-3">
                <ta-button class="ml-2">
                    Save
                </ta-button>
            </div>
        </form>
    </div>
</template>

<script>
import axios from "axios"

import InputText from "~/components/InputText"
import TaButton from "~/components/TaButton"

export default {
    data() {
        return {
            form: {
                sn: "",
                secureId: "",
                pinCode: "",
                mac: "",
                alias: "",
                model: "",
                lockedAt: null,
                userId: null,
                linkedAt: null,
            }
        }
    },

    methods: {
        async submit() {
            try {
                await axios.post("/dev/new-device", {
                    device: this.form
                })
                this.$store.dispatch("pushNotification", {
                    type: "success",
                    text: "Device created"
                })
                this.$emit("done")
            } catch (e) {
                this.$store.dispatch("pushNotification", {
                    type: "error",
                    text: e.toString()
                })
            }
        }
    },

    components: {
        InputText,
        TaButton
    }
}
</script>

<style scoped>
.dialog-box {
    width: calc(100% - 2rem);
}
</style>
