export default {
    install(Vue, options) {
        const breakpoints = options.breakpoints || {}

        const current = Vue.observable({})
        for (let name of Object.keys(breakpoints)) {
            Vue.set(current, name, false)
        }

        let hasSetupEventListeners = false

        Vue.mixin({
            computed: {
                $mq: () => current
            },

            created() {
                if (typeof(window) !== "undefined") {
                    if (hasSetupEventListeners) return

                    for (let [name, value] of Object.entries(breakpoints)) {
                        const mql = window.matchMedia(`(min-width: ${value}px)`)
                        Vue.set(current, name, mql.matches)
                        mql.addEventListener("change", e => {
                            Vue.set(current, name, e.matches)
                        })
                    }

                    hasSetupEventListeners = true
                } else if (options.initialSize) {
                    for (let [name, value] of Object.entries(breakpoints)) {
                        Vue.set(current, name, options.initialSize >= value)
                    }
                }
            }
        })
    }
}
