<template>
    <button
        class="rounded py-3 px-4 block"
        :class="buttonClass"
        :disabled="disabled"
        v-on="$listeners"
    >
        <slot />
    </button>
</template>

<script>
const themes = [
    "primary",
    "negative",
    "outline",
]

export default {
    props: {
        theme: {
            type: String,
            default: () => "primary",
            validator: theme => themes.indexOf(theme) != -1
        },
        disabled: {
            type: Boolean,
            default: false,
        }
    },

    computed: {
        buttonClass() {
            return {
                [this.theme]: true,
                "opacity-75": this.disabled,
            }
        }
    }
}
</script>

<style scoped>
button {
    transition: border 0.25s, background-color 0.25s;
}

.primary {
    @apply bg-gray-900 text-white;
}

.primary:focus, .btn.primary:hover {
    @apply bg-gray-800;
}

.negative:focus, .negative:hover {
    @apply bg-gray-200;
}

.outline {
    @apply border border-gray-900;
    box-sizing: border-box;
}
</style>
